import * as React from 'react';
import {
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiFieldText,
  EuiFlexItem,
  EuiSuperSelect,
  EuiBadge,
  EuiText,
  EuiAvatar,
  EuiSteps,
  EuiLink,
  EuiPanel,
  EuiListGroup,
  EuiListGroupItem,
} from '@elastic/eui';
import * as ResourcesEnums from '../../../../enums/Resources';
import * as Modals from '../../../Modals';
import * as utils from '../../../../utils';


function Payments(props) {
  // State
  const { isLoading, onSubmit, onUpdateForm, requestData, responseData, teamData, validationErrors, onRefreshTeamData, proMode } = props;
  const _updateForm = update => onUpdateForm({ ...requestData, ...update });
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [createAccountAccountType, setCreateAccountAccountType] = React.useState(null);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = React.useState(false);
  const closeCreateAccountModal = () => {
    setIsCreateAccountModalOpen(false);
    setIsCreateAccountModalOpen(null);
  };
  const openCreateAccountModal = (accountType) => {
    setCreateAccountAccountType(accountType);
    setIsCreateAccountModalOpen(true);
  };
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] = React.useState(false);
  const [isSelectingSource, setIsSelectingSource] = React.useState(true);
  const openSelectAccountModal = (isSource) => {
    setIsSelectingSource(isSource);
    setIsSelectAccountModalOpen(true);
  };
  const closeSelectAccountModal = () => setIsSelectAccountModalOpen(false);
  const onSelectAccount = account => _updateForm({ [isSelectingSource ? 'source' : 'destination']: account });
  const _onSubmit = () => {
    if (validationErrors.length > 0) {
      setSubmitAttempted(true);
    } else {
      onSubmit();
      setSubmitAttempted(false);
    }
  };
  const possibleSourceAccounts = teamData.accounts.filter(account => account.type === ResourcesEnums.AccountTypes.ACH.key && account.id !== requestData.destination);
  const possibleDestinationAccounts = teamData.accounts.filter(account => account.id !== requestData.source);
  const sourcePanelDetails = extractableTeam.getAccountPanelDetails(requestData.source);
  const destinationPanelDetails = extractableTeam.getAccountPanelDetails(requestData.destination);
  const validAmount = requestData.amount > 99;
  const amountStep = {
    title: '🔢 Amount',
    status: validAmount ? 'complete' : 'incomplete',
    children: (
      <EuiFieldText
        fullWidth
        disabled={responseData}
        type="number"
        value={(Number(requestData.amount) / 100) || null}
        isInvalid={submitAttempted && validationErrors.includes('amount')}
        placeholder="$50"
        onChange={e => _updateForm({ amount: Number(e.target.value) * 100 })} />
    ),
  };
  const sourceStep = {
    title: '🏦 Source',
    status: validAmount
      ? requestData.source ? 'complete' : 'incomplete'
      : 'disabled',
    get children() {
      if (this.status === 'disabled') return <></>;
      return (
        <>
          {possibleSourceAccounts.length === 0 && (
            <EuiButton fullWidth fill onClick={openCreateAccountModal}>
              Create account
            </EuiButton>
          )}
          {possibleSourceAccounts.length > 0 && !(requestData.source) && (
            <EuiButton fill fullWidth onClick={() => openSelectAccountModal(true)}>
              Select a source
            </EuiButton>
          )}
          {possibleSourceAccounts.length > 0 && requestData.source && (
            <EuiListGroup bordered={false} gutterSize="none">
              <EuiListGroupItem
                wrapText
                disabled={responseData}
                icon={<EuiAvatar size="l" type="space" name={sourcePanelDetails.emoji} initialsLength={2} color="#DCDDE1" />}
                onClick={() => openSelectAccountModal(true)}
                size="s"
                label={
                  <>
                    <p>{sourcePanelDetails.title}</p>
                    {sourcePanelDetails.badges.map(badge => (
                      <EuiBadge key={`${requestData.source}-${badge.text}`} color={badge.color}>
                        <small><b>{badge.text}</b></small>
                      </EuiBadge>
                    ))}
                  </>
                } />
            </EuiListGroup>
          )}
        </>
      );
    },
  };
  const destinationStep = {
    title: '💳 Destination',
    status: validAmount && requestData.source
      ? (requestData.destination) ? 'complete' : 'incomplete'
      : 'disabled',
    get children() {
      if (this.status === 'disabled') return <></>;
      return (
        <>
          {possibleDestinationAccounts.length === 0 && (
            <EuiButton fullWidth onClick={openCreateAccountModal}>
              Create account
            </EuiButton>
          )}
          {/* Select an source */}
          {possibleDestinationAccounts.length > 0 && !(requestData.destination) && (
            <EuiButton fullWidth fill onClick={() => openSelectAccountModal(false)}>
              Select a destination
            </EuiButton>
          )}
          {possibleDestinationAccounts.length > 0 && requestData.destination && (
            <EuiListGroup bordered={false} gutterSize="none">
              <EuiListGroupItem
                wrapText
                size="s"
                disabled={responseData}
                onClick={() => openSelectAccountModal(false)}
                icon={
                  destinationPanelDetails.image
                    ? <EuiAvatar size="l" name={destinationPanelDetails.image} imageUrl={destinationPanelDetails.image} />
                    : <EuiAvatar size="l" type="space" name={destinationPanelDetails.emoji} initialsLength={2} color="#dcdde1" />
                }
                label={
                  <>
                    <p>{destinationPanelDetails.title}</p>
                    {destinationPanelDetails.badges.map(badge => (
                      <EuiBadge key={`${requestData.destination}-${badge.text}`} color={badge.color}>
                        <small><b>{badge.text}</b></small>
                      </EuiBadge>
                    ))}
                  </>
                } />
            </EuiListGroup>
          )}
        </>
      );
    },
  };
  const submitStep = {
    title: '💸 Submit',
    status: (validAmount && requestData.source && requestData.destination) ? responseData ? 'complete' : 'incomplete' : 'disabled',
    get children() {
      if (this.status === 'disabled') return <></>;
      return (
        <EuiButton fill fullWidth isDisabled={Boolean(isLoading || responseData)} isLoading={isLoading} onClick={_onSubmit}>
          Create Payment
        </EuiButton>
      );
    },
  };

  const previousAccounts = utils.hooks.usePrevious(teamData.accounts);
  const previousPossibleSourceAccounts = utils.hooks.usePrevious(possibleSourceAccounts);
  const previousPossibleDestinationAccounts = utils.hooks.usePrevious(possibleDestinationAccounts);
  React.useEffect(() => {
    if (!proMode) _updateForm({ description: 'Simple Pmt' });
  }, []); // eslint-disable-line
  React.useEffect(() => {
    if (!requestData.source && previousPossibleSourceAccounts && previousPossibleSourceAccounts.length !== possibleSourceAccounts.length &&  possibleSourceAccounts.length === 1) {
      const [account] = possibleSourceAccounts;
      _updateForm({ source: account.id });
    }
    if (!requestData.destination && requestData.source && previousPossibleDestinationAccounts && previousPossibleDestinationAccounts.length !== possibleDestinationAccounts.length && possibleDestinationAccounts.length === 1) {
      const [account] = possibleDestinationAccounts;
      _updateForm({ destination: account.id });
    }
  }, [possibleSourceAccounts, possibleDestinationAccounts]); // eslint-disable-line

  React.useEffect(() => {
    if (previousAccounts && previousAccounts.length !== teamData.accounts.length) {
      const [newAccount] = teamData.accounts.filter(acc1 => !previousAccounts.some(acc2 => acc2.id === acc1.id));
      if (newAccount.ach && isSelectingSource) _updateForm({ source: newAccount.id });
      if (newAccount.ach && !isSelectingSource) _updateForm({ destination: newAccount.id });
      if (newAccount.liability && !isSelectingSource) _updateForm({ destination: newAccount.id });
    }
  }, [teamData.accounts]);

  return (
    <EuiFlexGroup direction="column">
      {proMode ? (
        <>
          <EuiFlexItem>
            <EuiForm component="form">
              {/* source */}
              <EuiFormRow
                label="Source"
                fullWidth
                isInvalid={submitAttempted && validationErrors.includes('source')}
                labelAppend={!proMode && possibleSourceAccounts.length > 0 && (
                  <EuiText size="xs">
                    <EuiLink disabled={responseData} onClick={() => openCreateAccountModal(ResourcesEnums.AccountTypes.ACH.key)}>
                      + Create an account
                    </EuiLink>
                  </EuiText>
                )}>
                {possibleSourceAccounts.length > 0 ? (
                  <EuiSuperSelect
                    hasDividers
                    fullWidth
                    itemLayoutAlign="top"
                    disabled={responseData}
                    valueOfSelected={requestData.source || ''}
                    isInvalid={submitAttempted && validationErrors.includes('source')}
                    onChange={source => _updateForm({ source })}
                    prepend={
                      extractableTeam.getAccount(requestData.source)
                        ? extractableTeam.getEntity(extractableTeam.getAccount(requestData.source).holder_id).individual
                        ? ResourcesEnums.EntityTypes.Individual.emoji
                        : ResourcesEnums.EntityTypes.CCorporation.emoji
                        : null
                    }
                    options={possibleSourceAccounts.map((account) => {
                      const badges = extractableTeam.getBadgesForResource(account, ResourcesEnums.ResourceTypes.Accounts.key);
                      return {
                        value: account.id,
                        inputDisplay: extractableTeam.toPresentableAccount(account),
                        dropdownDisplay: (
                          <React.Fragment>
                            <p>
                              <strong>
                                {account
                                  ? extractableTeam.getEntity(account.holder_id).individual
                                    ? ResourcesEnums.EntityTypes.Individual.emoji
                                    : ResourcesEnums.EntityTypes.CCorporation.emoji
                                  : null}
                                {' '}
                                {extractableTeam.toPresentableAccount(account)}
                              </strong>
                            </p>
                            {badges.map(badge => (
                              <EuiBadge color={badge.color} key={`${account.id}-${badge.text}`}>
                                <small><b>{badge.text}</b></small>
                              </EuiBadge>
                            ))}
                          </React.Fragment>
                        ),
                      };
                    })} />
                ) : (
                  <EuiButton iconType="listAdd" iconSide="right" fullWidth isDisabled={responseData} onClick={() => openCreateAccountModal(ResourcesEnums.AccountTypes.ACH.key)}>
                    Create an account
                  </EuiButton>
                )}
              </EuiFormRow>
              {/* destination */}
              <EuiFormRow label="Destination" fullWidth isInvalid={submitAttempted && validationErrors.includes('destination')}>
                {possibleDestinationAccounts.length > 0 ? (
                  <EuiSuperSelect
                    hasDividers
                    fullWidth
                    itemLayoutAlign="top"
                    disabled={responseData}
                    valueOfSelected={requestData.destination || ''}
                    isInvalid={submitAttempted && validationErrors.includes('destination')}
                    onChange={destination => _updateForm({ destination })}
                    prepend={
                      extractableTeam.getAccount(requestData.destination)
                        ? extractableTeam.getEntity(extractableTeam.getAccount(requestData.destination).holder_id).individual
                        ? ResourcesEnums.EntityTypes.Individual.emoji
                        : ResourcesEnums.EntityTypes.CCorporation.emoji
                        : null
                    }
                    options={possibleDestinationAccounts.map((account) => {
                      const badges = extractableTeam.getBadgesForResource(account, ResourcesEnums.ResourceTypes.Accounts.key);
                      return {
                        value: account.id,
                        inputDisplay: extractableTeam.toPresentableAccount(account),
                        dropdownDisplay: (
                          <React.Fragment>
                            <p>
                              <strong>
                                {account
                                  ? extractableTeam.getEntity(account.holder_id).individual
                                    ? ResourcesEnums.EntityTypes.Individual.emoji
                                    : ResourcesEnums.EntityTypes.CCorporation.emoji
                                  : null}
                                {' '}
                                {extractableTeam.toPresentableAccount(account)}
                              </strong>
                            </p>
                            {badges.map(badge => (
                              <EuiBadge color={badge.color} key={`${account.id}-${badge.text}`}>
                                <small><b>{badge.text}</b></small>
                              </EuiBadge>
                            ))}
                          </React.Fragment>
                        ),
                      };
                    })} />
                ) : (
                  <EuiButton iconType="listAdd" iconSide="right" fullWidth isDisabled={responseData} onClick={openCreateAccountModal}>
                    Create an account
                  </EuiButton>
                )}
              </EuiFormRow>
              {/* amount + description */}
              <EuiFormRow fullWidth>
                <EuiFlexGroup>
                  {/* amount */}
                  <EuiFlexItem>
                    <EuiFormRow fullWidth label="Amount" isInvalid={submitAttempted && validationErrors.includes('amount')}>
                      <EuiFieldText
                        fullWidth
                        disabled={responseData}
                        type="number"
                        value={(Number(requestData.amount) / 100) || null}
                        isInvalid={submitAttempted && validationErrors.includes('amount')}
                        placeholder="$50"
                        onChange={e => _updateForm({ amount: Number(e.target.value) * 100 })} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  {/* description */}
                  <EuiFlexItem>
                    <EuiFormRow fullWidth label="Description" isInvalid={submitAttempted && validationErrors.includes('description')}>
                      <EuiFieldText
                        fullWidth
                        disabled={responseData}
                        maxLength={10}
                        value={requestData.description}
                        placeholder="Maximum of 10 characters"
                        isInvalid={submitAttempted && validationErrors.includes('description')}
                        onChange={e => _updateForm({ description: e.target.value })} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            </EuiForm>
          </EuiFlexItem>
          <EuiFlexItem>
            {/* submit */}
            <EuiButton fill fullWidth isDisabled={Boolean(isLoading || responseData)} isLoading={isLoading} onClick={_onSubmit}>Create Payment</EuiButton>
          </EuiFlexItem>
        </>
      ) : (
        <EuiPanel paddingSize="s" hasBorder={false} hasShadow={false}>
          <EuiSteps titleSize="xs" steps={[amountStep, sourceStep, destinationStep, submitStep]} />
        </EuiPanel>
      )}

      <Modals.CreateAccount
        open={isCreateAccountModalOpen}
        onClose={closeCreateAccountModal}
        teamData={teamData}
        onRefreshTeamData={onRefreshTeamData}
        accountType={createAccountAccountType} />

      {/* TODO: text */}
      <Modals.SelectAccount
        title={isSelectingSource ? 'Select the source account' : 'Select the destination account'}
        subtitle={isSelectingSource ? `We'll pull the funds from this account and send it to the destination.` : `This account will receive the funds originating from the source account.`}
        open={isSelectAccountModalOpen}
        onClose={closeSelectAccountModal}
        onSelect={onSelectAccount}
        teamData={teamData}
        accounts={isSelectingSource ? possibleSourceAccounts : possibleDestinationAccounts}
        onOpenCreateAccount={openCreateAccountModal} />
    </EuiFlexGroup>
  );
}

export default Payments;
