import * as React from 'react';
import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
} from '@elastic/eui';


// TODO: text
function ZeroEntities(props) {
  const { onContinue } = props;
  return (
    <EuiPanel hasBorder paddingSize="s">
      <EuiEmptyPrompt
        iconType="usersRolesApp"
        iconColor={null}
        title={<h2>Zero entities</h2>}
        titleSize="s"
        actions={(
          <EuiFlexGroup responsive={false} gutterSize="s" justifyContent="center" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButton fill onClick={onContinue}>
                Create entity
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        body={
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </p>
        } />
    </EuiPanel>
  );
}

export default ZeroEntities;
