import * as React from 'react';
import { usePlaidLink } from 'react-plaid-link';


export default function HoistPlaidLinkHook(props) {
  const { config, onOpen } = props;

  const plaidLink = usePlaidLink(config);
  React.useEffect(() => {
    if (config.token && plaidLink.ready) {
      plaidLink.open();
      onOpen();
    }
  }, [config.token, plaidLink.ready]);

  return <div />;
}
