import Cookies from 'js-cookie';
import * as ResourcesEnums from "../enums/Resources";

export function extractFromTeamData(teamData) {
  const API = {
    getEntity: id => teamData.entities.find(x => x.id === id),
    getAccount: id => teamData.accounts.find(x => x.id === id),
    getMerchant: id => teamData.merchants.find(x => x.mch_id === id),
    getMerchantWithPlaidId: id => teamData.merchants.find(x => x.provider_ids.plaid.includes(id)),
    getMerchantSample: id => ResourcesEnums.MerchantsWithAvailableSample[id],
    toPresentableOwner: owner => `${owner.first_name} ${owner.last_name}`,
    toPresentableEntity: (entity) => {
      return entity.type === ResourcesEnums.EntityTypes.Individual.key
        ? `${entity.individual.first_name} ${entity.individual.last_name}`
        : `${entity.corporation.name}`;
    },
    toPresentableAccount: (account) => {
      const holder = API.getEntity(account.holder_id);
      const holderName = holder.type === ResourcesEnums.EntityTypes.Individual.key
        ? `${holder.individual.first_name} ${holder.individual.last_name}`
        : `${holder.corporation.name}`;
      return account.type === ResourcesEnums.AccountTypes.ACH.key
        ? `${holderName}'s ${(holder.corporation) ? `corporate ${account.ach.type}` : `personal ${account.ach.type}`}`
        : `${holderName}'s  ${API.getMerchant(account.liability.mch_id).name}`;
    },
    toPresentablePayment: (payment) => {
      const presentableDestination = API.toPresentableAccount(API.getAccount(payment.destination));
      return `$${payment.amount / 100} to ${presentableDestination}`;
    },
    getBadgesForResource: (resource, resourceType) => {
      const badges = [];
      const _getBadgesForMerchant = merchant => merchant.types.reduce((acc, type) => {
        switch (type) {
          case ResourcesEnums.MerchantTypes.CreditCard.key:
            acc.push({ text: ResourcesEnums.MerchantTypes.CreditCard.presentable, color: 'hollow' });
            break;
          case ResourcesEnums.MerchantTypes.StudentLoans.key:
            acc.push({ text: ResourcesEnums.MerchantTypes.StudentLoans.presentable, color: 'hollow' });
            break;
          default:
        }
        return acc
      }, []);

      switch(resourceType) {
        // Entities
        case ResourcesEnums.ResourceTypes.Entities.key: {
          switch (resource.type) {
            case ResourcesEnums.EntityTypes.Individual.key:
              badges.push({ text: ResourcesEnums.EntityTypes.Individual.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.EntityTypes.CCorporation.key:
              badges.push({ text: ResourcesEnums.EntityTypes.CCorporation.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.EntityTypes.SCorporation.key:
              badges.push({ text: ResourcesEnums.EntityTypes.SCorporation.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.EntityTypes.LLC.key:
              badges.push({ text: ResourcesEnums.EntityTypes.LLC.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.EntityTypes.Partnership.key:
              badges.push({ text: ResourcesEnums.EntityTypes.Partnership.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.EntityTypes.SoleProp.key:
              badges.push({ text: ResourcesEnums.EntityTypes.SoleProp.presentable, color: 'hollow' });
              break;
            default:
          }
          break;
        }
        // Accounts
        case ResourcesEnums.ResourceTypes.Accounts.key: {
          switch (resource.type) {
            case ResourcesEnums.AccountTypes.ACH.key:
              badges.push({ text: ResourcesEnums.AccountTypes.ACH.presentable, color: 'hollow' });
              if (resource.ach.type === ResourcesEnums.AccountACHTypes.Savings.key)
                badges.push({ text: ResourcesEnums.AccountACHTypes.Savings.presentable, color: 'hollow' });
              if (resource.ach.type === ResourcesEnums.AccountACHTypes.Checking.key)
                badges.push({ text: ResourcesEnums.AccountACHTypes.Checking.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.AccountTypes.Liability.key:
              badges.push({ text: ResourcesEnums.AccountTypes.Liability.presentable, color: 'hollow' });
              badges.push(..._getBadgesForMerchant(API.getMerchant(resource.liability.mch_id)));
              break;
            default:
          }
          break;
        }
        // Payments
        case ResourcesEnums.ResourceTypes.Payments.key: {
          switch (resource.status) {
            case ResourcesEnums.PaymentStatusTypes.Pending.key:
              badges.push({ text: ResourcesEnums.PaymentStatusTypes.Pending.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.PaymentStatusTypes.Sent.key:
              badges.push({ text: ResourcesEnums.PaymentStatusTypes.Sent.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.PaymentStatusTypes.Canceled.key:
              badges.push({ text: ResourcesEnums.PaymentStatusTypes.Canceled.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.PaymentStatusTypes.Returned.key:
              badges.push({ text: ResourcesEnums.PaymentStatusTypes.Returned.presentable, color: 'hollow' });
              break;
            case ResourcesEnums.PaymentStatusTypes.Error.key:
              badges.push({ text: ResourcesEnums.PaymentStatusTypes.Error.presentable, color: 'hollow' });
              break;
            default:
          }
          break;
        }
        // Merchants
        case ResourcesEnums.ResourceTypes.Merchant.key: {
          badges.push(..._getBadgesForMerchant(resource));
          const sample = API.getMerchantSample(resource.mch_id);
          if (sample) badges.push({ text: '🌟 Sandbox', color: 'hollow' });
          break;
        }
        default:
      }
      return badges;
    },
    getAccountPanelDetails: (accountId) => {
      if (!accountId) return { account: null, title: null, badges: [] };
      const account = API.getAccount(accountId);
      const isACH = account.type === ResourcesEnums.AccountTypes.ACH.key;
      const emoji = isACH ? ResourcesEnums.AccountTypes.ACH.emoji : ResourcesEnums.AccountTypes.Liability.emoji;
      const title = API.toPresentableAccount(account);
      const badges = API.getBadgesForResource(account, ResourcesEnums.ResourceTypes.Accounts.key);
      const image = isACH ? null : API.getMerchant(account.liability.mch_id).logo;
      return { account, title, emoji, badges, image };
    },
  };

  return API;
}

export function formatCorpOwnerPayload(owner) {
  return {
    first_name: owner.firstName,
    last_name: owner.lastName,
    phone: owner.phone,
    email: owner.email,
    dob: owner.dob,
    address: { line1: owner.addressLine1, line2: owner.addressLine2, city: owner.addressCity, state: owner.addressState, zip: owner.addressZip },
  };
}

export function formatCreateRequestPayload(type, requestData) {
  switch (type) {
    case ResourcesEnums.ResourceTypes.Entities.key: {
      if (!requestData.type) return {};
      const payload = (requestData.type === ResourcesEnums.EntityTypes.Individual.key)
        ? ({
          individual: {
            first_name: requestData.firstName,
            last_name: requestData.lastName,
            phone: requestData.phone,
            email:requestData.email,
            dob:requestData.dob,
          },
        }) : ({
          corporation: {
            name: requestData.name,
            ein: requestData.ein,
            owners: [],
          },
        });
      payload.type = requestData.type;
      payload.address = { line1: requestData.addressLine1, line2: requestData.addressLine2, city: requestData.addressCity, state: requestData.addressState, zip: requestData.addressZip };
      return payload;
    }
    case ResourcesEnums.ResourceTypes.Accounts.key: {
      if (!requestData.type) return {};
      const payload = (requestData.type === ResourcesEnums.AccountTypes.Liability.key)
        ? ({ liability: { mch_id: requestData.merchant, account_number: requestData.number } })
        : ({ ach: { routing: requestData.routing, number: requestData.number, type: requestData.achType } });
      payload.holder_id = requestData.holder;
      return payload;
    }
    case ResourcesEnums.ResourceTypes.Payments.key: return {
      amount: requestData.amount,
      source: requestData.source,
      destination: requestData.destination,
      description: requestData.description,
    };
    default:
  }
}

export function formatCreateRequestPayloadFromResponse(type, responseData) {
  switch (type) {
    case ResourcesEnums.ResourceTypes.Entities.key: {
      const payload = (responseData.type === ResourcesEnums.EntityTypes.Individual.key)
        ? ({ individual: { ...responseData.individual } })
        : ({ corporation: { ...responseData.corporation } });
      payload.type = responseData.type;
      payload.address = responseData.address;
      return payload;
    }
    case ResourcesEnums.ResourceTypes.Accounts.key: {
      const payload = (responseData.type === ResourcesEnums.AccountTypes.Liability.key)
        ? ({ liability: responseData.liability })
        : ({ ach: responseData.ach });
      payload.holder_id = responseData.holder_id;
      return payload;
    }
    case ResourcesEnums.ResourceTypes.Payments.key: return {
      amount: responseData.amount,
      source: responseData.source,
      destination: responseData.destination,
      description: responseData.description,
    };
    default:
  }
}

export function getInitialResourcesState() {
  return [
    {
      key: ResourcesEnums.ResourceTypes.Entities.key,
      emoji: ResourcesEnums.ResourceTypes.Entities.emoji,
      presentableSingular: ResourcesEnums.ResourceTypes.Entities.presentableSingular,
      requestData: {},
      responseData: null,
      title: 'Create an entity',
      description: '➡️ Select the type of entity, and enter a few details about it.',
      docsURL: 'https://docs.methodfi.com/docs/reference/docs/2.%20Entities/1-EntityObj.md',
      tooltipHoverContent: `Entities are a representation of your application's end-users. Throughout Method's ecosystem, an Entity is the legal owner of any Account.`,
      tooltipPresentedText: 'What is an entity?',
      resFooterTitle: 'You\'ve created an entity!',
      resFooterBody: 'An Entity is basically one of your users. Next we\'ll link some financial accounts to this user. You can virtually link any financial account including checking, savings, student loans, credit cards and much more.',
    },
    {
      key: ResourcesEnums.ResourceTypes.Accounts.key,
      emoji: ResourcesEnums.ResourceTypes.Accounts.emoji,
      presentableSingular: ResourcesEnums.ResourceTypes.Accounts.presentableSingular,
      requestData: {},
      responseData: null,
      title: 'Link an account',
      description: '➡️ Select the type of account, and enter the identifying details for it.',
      docsURL: 'https://docs.methodfi.com/docs/reference/docs/3.%20Accounts/1-AccountObj.md',
      tooltipHoverContent: `Accounts are a representation of an entity's financial accounts. An account can be a checking or savings account (ach) or a credit card, student loan, etc. (liability)`,
      tooltipPresentedText: 'What is an account?',
      resFooterTitle: 'You\'ve created an account!',
      resFooterBody: 'This account is ready to go. You can now move money to or from this account. Next we\'ll create a payment.',
    },
    {
      key: ResourcesEnums.ResourceTypes.Payments.key,
      emoji: ResourcesEnums.ResourceTypes.Payments.emoji,
      presentableSingular: ResourcesEnums.ResourceTypes.Payments.presentableSingular,
      requestData: {},
      responseData: null,
      title: 'Send a payment',
      description: '➡️ Select the sender, reciever and amount to send. It\'s that simple! ',
      docsURL: 'https://docs.methodfi.com/docs/reference/docs/5.%20Payments/1-PaymentObj.md',
      tooltipHoverContent: `Payments allow you to easily move money between two accounts. Using payments you can move money between two ach accounts (e.g. Checking & Savings) or move money (pay) a liability (e.g. Student Loans, Credit Cards, etc.)`,
      tooltipPresentedText: 'What is a payment?',
      resFooterTitle: 'You\'ve made a payment!',
      resFooterBody: 'It\'s that easy. Method will take care of the rest and move the money from the source to the destination. In production, payments are settled in 1-2 business days.',
    },
  ];
}

export function shouldWelcome(token) {
  return Boolean(Cookies.get(token));
}

export function removeShouldWelcome(token) {
  Cookies.set(token, '');
}

export function setShouldWelcome(token) {
  Cookies.set(token, '1');
}
