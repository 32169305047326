import * as React from 'react';
import Dinero from 'dinero.js';
import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageBody,
  EuiPageContent,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';

import * as HOCs from '../../HOCs';
import * as WizardSteps from '../Wizard/Steps';
import * as utils from '../../../utils';
import * as api from '../../../api';
import * as ResourcesEnums from '../../../enums/Resources';


function Basic(props) {
  const { onRefreshTeamData, teamData, setCodeBlockModalState } = props;
  const resourceKey = ResourcesEnums.ResourceTypes.Payments.key;
  const resourceState = utils.helpers.getInitialResourcesState().filter(({ key }) => key === resourceKey)[0];
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestData, setRequestData] = React.useState(resourceState.requestData);
  const [responseData, setResponseData] = React.useState(null);
  const formattedPayload = utils.helpers.formatCreateRequestPayload(resourceKey, requestData);
  const payloadValidation = utils.validators.validatePayload(resourceKey, formattedPayload);
  const validationErrors = payloadValidation.error ? payloadValidation.error.details.map(x => x.context.label) : [];
  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setResponseData(await api.createResource(resourceKey, formattedPayload));
      onRefreshTeamData();
      document.getElementById('result-panel').scrollIntoView();
    } catch (error) {
      props.addToast({
        title: 'Oops, there was an error',
        color: 'danger',
        iconType: 'alert',
        text: <p>{error.response.data.message}</p>,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const clearResource = () => {
    setRequestData({ description: 'Simple Pmt', amount: 100 });
    setResponseData(null);
  };

  const toHumanizedPaymentDescription = () => {
    const items = [<span>Make a </span>];
    const amountStr = requestData.amount ? Dinero({ amount: requestData.amount, currency: 'USD' }).toFormat('$0,0.00') : null
    if (!amountStr) return null;
    const fromStr = requestData.source ? extractableTeam.toPresentableAccount(extractableTeam.getAccount(requestData.source)) : null;
    const toStr = requestData.destination ? extractableTeam.toPresentableAccount(extractableTeam.getAccount(requestData.destination)) : null;
    if (amountStr) items.push(...[<b>{amountStr}</b>, <span> payment <br/>from </span>]);
    if (fromStr) items.push(...[<b>{fromStr} account</b>, <span> <br/>to </span>]);
    if (toStr) items.push(<b>{toStr} account.</b>);
    return [<EuiSpacer size="s" />, <EuiText>{items}</EuiText>];
  };

  const isMobile = utils.hooks.useIsMobile();
  const height = isMobile ? 'calc(100vh - 48px)' : '100%';
  const onViewAPIResource = () => {
    setCodeBlockModalState({
      open: true,
      title: 'Payment',
      subtitle: extractableTeam.toPresentablePayment(responseData),
      resourceType: ResourcesEnums.ResourceTypes.Payments.key,
      requestType: 'GET',
      resource: responseData,
    });
  };



  return (
    <EuiPageBody paddingSize="l" style={{ paddingTop: 48, paddingLeft: 0, paddingRight: 0, minHeight: height, maxHeight: height }}>
      <EuiPageContent
        verticalPosition="center"
        horizontalPosition="center"
        style={{ width: 450, marginTop: isMobile ? 0 : 48, paddingTop: 48 }}>
        <EuiText>
          <h3>🌟 Method Payments Demo</h3>
        </EuiText>
        {toHumanizedPaymentDescription()}
        <EuiSpacer size="xl" key="4" />
        <WizardSteps.Payments
          requestData={requestData}
          responseData={responseData}
          teamData={teamData}
          onUpdateForm={setRequestData}
          onSubmit={onSubmit}
          isLoading={isLoading}
          validationErrors={validationErrors}
          onRefreshTeamData={onRefreshTeamData}
          proMode={false} />
        <EuiSpacer key="5" />
        {responseData && (
          <EuiPanel hasBorder paddingSize="l" id="result-panel">
            <EuiEmptyPrompt
              iconType="usersRolesApp"
              iconColor={null}
              title={<h2>{resourceState.resFooterTitle}</h2>}
              titleSize="s"
              body={<p>{resourceState.resFooterBody}</p>}
            actions={(
              <EuiFlexGroup responsive={false} gutterSize="s" justifyContent="center" alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiButton display="base" size="s" iconType="console" iconSide="right" aria-label="More" onClick={onViewAPIResource}>
                    See API response
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton display="base" size="s" fill iconType="indexOpen" iconSide="right" aria-label="More" onClick={clearResource}>
                    Create another
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}/>
          </EuiPanel>
        )}

      </EuiPageContent>

    </EuiPageBody>
  );
}

export default HOCs.withGlobalToastList(Basic);
