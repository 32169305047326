const ResourceTypes = {
  Entities: { key: 'Entities', presentable: 'Entities', presentableSingular: 'Entity', emoji: '🏛️' },
  Accounts: { key: 'Accounts', presentable: 'Accounts', presentableSingular: 'Account', emoji: '💵' },
  Payments: { key: 'Payments', presentable: 'Payments', presentableSingular: 'Payment', emoji: '💸' },
  Merchant: { key: 'Merchant', presentable: 'Merchant', presentableSingular: 'Merchant', emoji: '💸' },
};

const EntityTypes = {
  Individual: { key: 'individual', presentable: 'Individual', emoji: '🧑️' },
  CCorporation: { key: 'c_corporation', presentable: 'C Corporation', emoji: '🏢' },
  SCorporation: { key: 's_corporation', presentable: 'S Corporation', emoji: '🏢' },
  LLC: { key: 'llc', presentable: 'Limited Liability Company', emoji: '🏢' },
  Partnership: { key: 'partnership', presentable: 'Partnership', emoji: '🏢' },
  SoleProp: { key: 'sole_proprietorship', presentable: 'Sole Proprietorship', emoji: '🏢' },
}

const AccountTypes = {
  ACH: { key: 'ach', presentable: 'ACH', emoji: '🏦' },
  Liability: { key: 'liability', presentable: 'Liability', emoji: '💳' },
};

const AccountACHTypes = {
  Checking: { key: 'checking', presentable: 'Checking', emoji: '#️⃣' },
  Savings: { key: 'savings', presentable: 'Savings', emoji: '💰' },
};

const PaymentStatusTypes = {
  Pending: { key: 'pending', presentable: 'Pending' },
  Sent: { key: 'sent', presentable: 'Sent' },
  Canceled: { key: 'canceled', presentable: 'Canceled' },
  Returned: { key: 'returned', presentable: 'Returned' },
  Error: { key: 'error', presentable: 'Error' },
};

const MerchantTypes = {
  StudentLoans: { key: 'student_loan', presentable: 'Student Loan' },
  CreditCard: { key: 'credit_card', presentable: 'Credit Card' },
};

const MerchantsWithAvailableSample = {
  // TODO: define the samples
  // // Chase
  mch_1: { account_number: '4147202384343323' },
  mch_2: { account_number: '9684930234' },
  mch_3: { account_number: '371307773565049' },
  mch_4: { account_number: '4857438890' },
  mch_5: { account_number: '1023948346' },
  mch_6: { account_number: '2938009233' },
  mch_7: { account_number: '0947283259' },
  mch_8: { account_number: 'F093842345' },
  // mch_9: {},
  // mch_10: {},
  // mch_11: {},
  // mch_12: {},
  // mch_13: {},
  // mch_14: {},
  // mch_15: {},
  // mch_16: {},
  // mch_17: {},
  // mch_18: {},
  // mch_19: {},
};

export {
  ResourceTypes,
  EntityTypes,
  AccountTypes,
  AccountACHTypes,
  PaymentStatusTypes,
  MerchantTypes,
  MerchantsWithAvailableSample,
};
