import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiText,
  EuiSpacer,
  EuiIcon,
  EuiLink,
} from '@elastic/eui';
import { useMethodLink } from 'react-method-link';

import * as HOCs from '../HOCs';
import * as WizardSteps from '../Panels/Wizard/Steps';
import * as utils from '../../utils';
import * as api from '../../api';
import * as ResourcesEnums from '../../enums/Resources';
import HoistPlaidLinkHook from '../HoistPlaidLinkHook';
import { AccountTypes } from '../../enums/Resources';

function CreateAccount(props) {
  const { open, onClose, teamData, onRefreshTeamData, accountType } = props;
  const resourceKey = ResourcesEnums.ResourceTypes.Accounts.key;
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);
  const resourceState = utils.helpers.getInitialResourcesState().filter(({ key }) => key === resourceKey)[0];
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestData, setRequestData] = React.useState({ ...resourceState.requestData, type: accountType || null });
  const [responseData, setResponseData] = React.useState(null);
  const formattedPayload = utils.helpers.formatCreateRequestPayload(resourceKey, requestData);
  const payloadValidation = utils.validators.validatePayload(resourceKey, formattedPayload);
  const validationErrors = payloadValidation.error ? payloadValidation.error.details.map(x => x.context.label) : [];
  const isMobile = utils.hooks.useIsMobile();
  const _onClose = () => {
    setResponseData(null);
    setIsLoading(false);
    setRequestData({});
    onClose();
  };
  const openSuccessToastForAccount = (account) => {
    const accountName = extractableTeam.toPresentableAccount(account);
    props.addToast({
      title: 'New account created',
      color: 'success',
      text: <p>{accountName} is now ready to be used.</p>,
    })
  };
  const openErrorToastWithError = (error) => {
    props.addToast({
      title: 'Oops, there was an error',
      color: 'danger',
      iconType: 'alert',
      text: <p>{error.response.data.message}</p>,
    });
  };
  const openErrorToastForMissingMerchant = (merchantName) => {
    props.addToast({
      title: 'Unsupported merchant',
      color: 'warning',
      iconType: 'alert',
      text: <p>Unfortunately {merchantName} is currently not supported by Method.</p>,
    });
  };
  const onSubmit = async (overwritePayload) => {
    try {
      setIsLoading(true);
      const res = await api.createResource(resourceKey, { ...formattedPayload, ...(overwritePayload || {}) });
      setResponseData(res);
      onRefreshTeamData();
      _onClose();
      openSuccessToastForAccount(res);
    } catch (error) {
      openErrorToastWithError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const [plaidLinkToken, setPlaidLinkToken] = React.useState(null);

  const { openWithToken } = useMethodLink({
    env: 'dev',
    onOpen: onClose, // close main account modal again
    onSuccess: data => api.exchangeLinkToken(data)
      .then((account) => {
        openSuccessToastForAccount(account);
        _onClose();
      })
      .catch(openErrorToastWithError)
      .finally(onRefreshTeamData),
  });

  const onOpenLink = async (options: { withPlaid: boolean } = { withPlaid: false }) => {
    if (!requestData.holder) return;

    return options.withPlaid
      ? api.fetchPlaidLinkToken({ token: utils.url.getToken(), team_name: teamData.team.name }).then(setPlaidLinkToken)
      : api.fetchLinkToken({ entity_id: requestData.holder }).then(openWithToken);
  };

  if (!open) return null;

  return (
    <EuiModal onClose={_onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <EuiSpacer size="s"/>
          <EuiText size="s">
            <h1>
              {ResourcesEnums.ResourceTypes.Accounts.emoji}
              {' '}
              Create an account
            </h1>
            {teamData.entities.length > 0 && (
              <EuiText size="s" grow={false}>
                <p><b>{resourceState.description}{' '}</b>
                <br />
                <EuiLink
                  href="#"
                  onClick={() => !Boolean(responseData) && setRequestData(utils.autofill(resourceKey, teamData))}>
                  Fill with sample account <EuiIcon type="indexEdit" />
                </EuiLink>
                </p>
              </EuiText>
            )}
          </EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      {plaidLinkToken && (
        <HoistPlaidLinkHook
          onOpen={_onClose}
          config={{
            token: plaidLinkToken,
            onSuccess: () => {},
            onExit: () => setPlaidLinkToken(''),
            onEvent: (type, data) => {
              if (type === 'HANDOFF') {
                const merchant = extractableTeam.getMerchantWithPlaidId(data.institution_id)
                if (!merchant) {
                  openErrorToastForMissingMerchant(data.institution_name);
                  return setPlaidLinkToken('');
                }
                const [merchant_type] = merchant.types;
                switch (merchant_type) {
                  case 'student_loan':
                    onSubmit({
                      holder_id: requestData.holder,
                      liability: { mch_id: merchant.mch_id, account_number: '9832723822' },
                    });
                    break;
                  default:
                    api.fetchLinkToken({
                      entity_id: requestData.holder,
                      mch_id: merchant.mch_id,
                      mask: '3023',
                    }).then(openWithToken);
                    break;
                }
                setPlaidLinkToken('');
              }
            },
          }} />
      )}

      <EuiModalBody style={{ minWidth: isMobile ? '100%' : 500 }}>
        <WizardSteps.Accounts
          requestData={requestData}
          responseData={responseData}
          teamData={teamData}
          onUpdateForm={setRequestData}
          onSubmit={onSubmit}
          isLoading={isLoading}
          validationErrors={validationErrors}
          onRefreshTeamData={onRefreshTeamData}
          proMode={false}
          onOpenLink={onOpenLink} />
      </EuiModalBody>
    </EuiModal>
  );
}

export default HOCs.withGlobalToastList(CreateAccount);
