import * as React from 'react';
import { EuiPage, EuiLoadingSpinner } from '@elastic/eui';

import * as utils from '../utils';
import * as api from '../api';
import * as Modals from './Modals';
import * as Panels from './Panels';


function Main() {
  // State
  const token = utils.url.getToken();
  const proMode = utils.url.isProMode();
  const plaidFlowMode = utils.url.isPlaidFlowMode();
  const [getStartedModalOpen, setGetStartedModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [teamData, setTeamData] = React.useState(null);
  const [refreshingTeamData, setRefreshingTeamData] = React.useState(false);
  const [codeBlockModalState, setCodeBlockModalState] = React.useState({ open: false, resource: null, resourceType: null, title: null, requestType: null });
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  // Methods
  const onCodeBlockModalClose = () => setCodeBlockModalState({ open: false, resource: null, resourceType: null, title: null, requestType: null });
  const onRefreshTeamData = () => {
    setRefreshingTeamData(true);
    (async () => {
      try {
        setTeamData(await api.fetchTeamData());
      } catch (error) {
        console.log(error);
      } finally {
        setRefreshingTeamData(false);
      }
    })();
  };

  // Effects
  React.useEffect(() => {
    if (token) {
      api.fetchTeamData()
        .then((data) => {
          setTeamData(data);
          setIsLoading(false);
        })
        .catch(() => {
          window.location.href = window.location.origin;
        });
    } else {
      setGetStartedModalOpen(true);
      setIsLoading(false);
    }
  }, [token]);

  if (isLoading) return (
    <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <EuiLoadingSpinner size="xl" />
    </div>
  );
  if (!teamData) return <Modals.GetStarted open={getStartedModalOpen} />;

  return (
    <EuiPage paddingSize="none">
      <Modals.Welcome token={token} teamData={teamData} />

      {/* Flow for Non-Pro */}
      {!plaidFlowMode && !proMode && [
        <Panels.Topbar
          key="Topbar"
          teamData={teamData}
          setCodeBlockModalState={setCodeBlockModalState}
          toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />,
        <Panels.Basic
          key="Basic"
          teamData={teamData}
          setCodeBlockModalState={setCodeBlockModalState}
          onRefreshTeamData={onRefreshTeamData} />
      ]}

      {/* Flow for Pro */}
      {!plaidFlowMode && proMode && [
        <Panels.Topbar
          proMode
          key="Topbar"
          teamData={teamData}
          setCodeBlockModalState={setCodeBlockModalState}
          toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />,
        <Panels.Sidebar
          proMode
          key="Sidebar"
          teamData={teamData}
          refreshingTeamData={refreshingTeamData}
          isOpen={isSidebarOpen}
          closeSidebar={() => setIsSidebarOpen(false)}
          setCodeBlockModalState={setCodeBlockModalState} />,
        <Panels.Wizard
          proMode
          key="Wizard"
          teamData={teamData}
          onRefreshTeamData={onRefreshTeamData}
          setCodeBlockModalState={setCodeBlockModalState} />,
      ]}

      {plaidFlowMode && !proMode && [
        <Panels.Topbar
          key="Topbar"
          teamData={teamData}
          setCodeBlockModalState={setCodeBlockModalState}
          toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />,
        <Panels.Flow
          key="Flow"
          teamData={teamData}
          setCodeBlockModalState={setCodeBlockModalState}
          onRefreshTeamData={onRefreshTeamData} />
      ]}

      <Modals.CodeBlock
        key="CodeBlock"
        {...codeBlockModalState}
        onClose={onCodeBlockModalClose} />
    </EuiPage>
  );
}

export default Main;
