import Main from './components/Main';

require('@elastic/eui/dist/eui_theme_amsterdam_light.css');

// if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
//   require('@elastic/eui/dist/eui_theme_amsterdam_dark.css');
// } else {
//   require('@elastic/eui/dist/eui_theme_amsterdam_light.css');
// }

function App() {
  return <Main />;
}

export default App;
