import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiText,
  EuiSpacer,
} from '@elastic/eui';

import * as HOCs from '../HOCs';
import * as WizardSteps from '../Panels/Wizard/Steps';
import * as utils from '../../utils';
import * as api from '../../api';
import * as ResourcesEnums from '../../enums/Resources';

function CreateEntity(props) {
  const { open, onClose, teamData, onRefreshTeamData } = props;
  const resourceKey = ResourcesEnums.ResourceTypes.Entities.key;
  const resourceState = utils.helpers.getInitialResourcesState().filter(({ key }) => key === resourceKey)[0];
  const [isLoading, setIsLoading] = React.useState(false);
  const [requestData, setRequestData] = React.useState(resourceState.requestData);
  const [responseData, setResponseData] = React.useState(null);
  const formattedPayload = utils.helpers.formatCreateRequestPayload(resourceKey, requestData);
  const payloadValidation = utils.validators.validatePayload(resourceKey, formattedPayload);
  const validationErrors = payloadValidation.error ? payloadValidation.error.details.map(x => x.context.label) : [];
  const isMobile = utils.hooks.useIsMobile();
  const _onClose = () => {
    setResponseData(null);
    setIsLoading(false);
    setRequestData({});
    onClose();
  };
  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setResponseData(await api.createResource(resourceKey, formattedPayload));
      onRefreshTeamData();
      _onClose();
    } catch (error) {
      props.addToast({
        title: 'Oops, there was an error',
        color: 'danger',
        iconType: 'alert',
        text: <p>{error.response.data.message}</p>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!open) return null;

  return (
    <EuiModal onClose={_onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <EuiSpacer size="s"/>
          <EuiText size="s">
            <h1>
              {ResourcesEnums.ResourceTypes.Entities.emoji}
              {' '}
              Create an entity
            </h1>
            <EuiText size="s" grow={false}>
              <p><b>{resourceState.description}</b></p>
            </EuiText>
          </EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody style={{ minWidth: isMobile ? '100%' : 500 }}>
        <WizardSteps.Entities
          requestData={requestData}
          responseData={responseData}
          teamData={teamData}
          onUpdateForm={setRequestData}
          onSubmit={onSubmit}
          isLoading={isLoading}
          validationErrors={validationErrors}
          onRefreshTeamData={onRefreshTeamData}
          proMode={false} />
      </EuiModalBody>
    </EuiModal>
  );
}

export default HOCs.withGlobalToastList(CreateEntity);
