import axios from 'axios';
import * as ResourcesEnums from "../enums/Resources";
import * as utils from '../utils';

const BASE_URL = `https://dev.methodfi.com`;
const headers = { Authorization: `Bearer ${utils.url.getToken()}` };


export async function fetchTeamData() {
  const [_team, _entities, _accounts, _payments, _merchants] = await Promise.all([
    axios({ url: `${BASE_URL}/teams`, headers }),
    axios({ url: `${BASE_URL}/entities`, headers }),
    axios({ url: `${BASE_URL}/accounts`, headers }),
    axios({ url: `${BASE_URL}/payments`, headers }),
    axios({ url: `${BASE_URL}/merchants`, headers }),
  ]);

  return {
    team: _team.data.data,
    entities: _entities.data.data,
    accounts: _accounts.data.data,
    payments: _payments.data.data,
    merchants: _merchants.data.data,
  };
}

export async function createEntity(payload) {
  return (await axios({ url: `${BASE_URL}/entities`, method: 'POST', data: payload, headers })).data.data;
}

export async function createAccount(payload) {
  return (await axios({ url: `${BASE_URL}/accounts`, method: 'POST', data: payload, headers })).data.data;
}

export async function createPayment(payload) {
  return (await axios({ url: `${BASE_URL}/payments`, method: 'POST', data: payload, headers })).data.data;
}

export async function createTeam(payload) {
  return (await axios({ url: `${BASE_URL}/public/demo/token`, method: 'POST', data: payload })).data.data;
}

export async function fetchLinkToken(payload) {
  return (await axios({ url: `${BASE_URL}/link/token`, method: 'POST', data: payload, headers })).data.data.link_token;
}

export async function exchangeLinkToken(payload) {
  return (await axios({ url: `${BASE_URL}/link/exchange`, method: 'POST', data: payload, headers })).data.data;
}

export async function fetchPlaidLinkToken(payload) {
  return (await axios({ url: 'https://methodfi-plaid-demo.jose.workers.dev/token', method: 'POST', data: payload })).data.link_token;
}

export async function createResource(type, payload) {
  return ({
    [ResourcesEnums.ResourceTypes.Entities.key]: createEntity,
    [ResourcesEnums.ResourceTypes.Accounts.key]: createAccount,
    [ResourcesEnums.ResourceTypes.Payments.key]: createPayment,
  })[type](payload);
}
