import axios from 'axios';


export async function requestForAPIKeys(email) {
  let meta;
  try {
    meta = await fetch('https://ipapi.co/json').then(res => res.json());
  } catch (_) {}

  const requestSections = [
    `Email: ${email}`,
    `Referrer: ${window.location.href}`,
  ];

  if (meta) requestSections.push(...['```', JSON.stringify(meta, null, 2), '```']);

  const requestOptions = {
    url: 'https://hooks.slack.com/services/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',
    method: 'POST',
    data: JSON.stringify({
      type: 'mrkdwn',
      text: requestSections.join('\n'),
    }),
  };

  axios(requestOptions).finally(() => {
    window.open(`https://form.typeform.com/to/Ow16j24X#email=${email}`, '_blank');
  });

  return null;
}
