import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiText,
  EuiSpacer,
} from '@elastic/eui';

import * as utils from '../../utils';


function Welcome(props) {
  const { token, teamData } = props;
  const [shouldWelcome, setShouldWelcome] = React.useState(utils.helpers.shouldWelcome(token));

  const onClose = () => {
    utils.helpers.removeShouldWelcome(token);
    setShouldWelcome(false);
  };

  if (!shouldWelcome) return null;

  return (
    <EuiModal maxWidth={400} onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          Welcome to Method, {teamData.team.name}!
          <EuiSpacer size="s"/>
          <EuiText size="s">
            <p>We've created a development environment, and added some sample entities & accounts. We'll guide you through creating an entity, linking an account and moving money!</p>
          </EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton fill fullWidth onClick={onClose}>Enter Demo</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default Welcome;
