import * as React from 'react';
import { EuiGlobalToastList } from "@elastic/eui";


export default function withGlobalToastList(WrappedComponent) {
  return function (props) {
    // State
    const [toasts, setToasts] = React.useState([]);

    // Methods
    const addToast = toast => setToasts(toasts.concat({ ...toast, id: Math.random() }));
    const removeToast = toast => setToasts(toasts.filter(x => x.id !== toast.id));

    return [
      <EuiGlobalToastList
        key="global_toasts"
        toasts={toasts}
        toastLifeTimeMs={6000}
        dismissToast={removeToast} />,
      <WrappedComponent
        key="wrapped_component"
        {...props}
        addToast={addToast} />
    ];
  }
}
