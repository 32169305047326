import Faker from 'faker';

import * as url from './url';
import * as ResourcesEnums from '../enums/Resources';


function getFakeIndividual() {
  return {
    firstName: Faker.name.firstName(),
    lastName: Faker.name.lastName(),
    phone: Faker.phone.phoneNumberFormat(),
    get email() {
      return `${this.firstName.toLowerCase()}.${this.lastName.toLowerCase()}@${Faker.internet.domainName()}`;
    },
    dob: '1990-03-18',
  };
}

function getFakeCorp() {
  return {
    name: Faker.company.companyName(0),
    ein: Faker.finance.account(9),
  };
}

function getFakeAddress() {
  return {
    addressLine1: Faker.address.streetAddress("###"),
    addressLine2: Faker.address.secondaryAddress(),
    addressCity: Faker.address.city(),
    addressState: Faker.address.stateAbbr(),
    addressZip: Faker.address.zipCode('#####'),
  };
}

export default function autofill(type, teamData, subtype = null) {
  switch (type) {
    case ResourcesEnums.ResourceTypes.Entities.key: {
      const possibleEntityTypes = url.isProMode()
        ? Object.values(ResourcesEnums.EntityTypes)
        : [ResourcesEnums.EntityTypes.Individual, ResourcesEnums.EntityTypes.CCorporation];

      const entityType = subtype || Faker.random.arrayElement(possibleEntityTypes.map(x => x.key));

      let fakeEntity = { type: entityType };
      if (entityType === ResourcesEnums.EntityTypes.Individual.key) fakeEntity = { ...fakeEntity, ...getFakeIndividual() };
      else fakeEntity = { ...fakeEntity, ...getFakeCorp() };
      fakeEntity = { ...fakeEntity, ...getFakeAddress() };
      return fakeEntity;
    }
    case ResourcesEnums.ResourceTypes.Accounts.key: {
      const accountType = Faker.random.arrayElement(Object.values(ResourcesEnums.AccountTypes).map(x => x.key));
      switch (accountType) {
        case ResourcesEnums.AccountTypes.ACH.key: {
          return {
            type: accountType,
            achType: Faker.random.arrayElement(Object.values(ResourcesEnums.AccountACHTypes).map(x => x.key)),
            holder: Faker.random.arrayElement(teamData.entities).id,
            number: Faker.finance.account(),
            routing: Faker.finance.routingNumber(),
          };
        }
        case ResourcesEnums.AccountTypes.Liability.key: {
          const sample = Faker.random.arrayElement(Object.entries(ResourcesEnums.MerchantsWithAvailableSample));
          const [sampleMchId, sampleData] = sample || [null, null];

          return {
            type: accountType,
            holder: Faker.random.arrayElement(teamData.entities).id,
            number: sampleData ? sampleData.account_number : null,
            merchant: sampleMchId
          };
        }
        default: return {};
      }
    }
    case ResourcesEnums.ResourceTypes.Payments.key: {
      const source = Faker.random.arrayElement(teamData.accounts.filter(account => account.ach !== null));
      const destinations = source
        ? teamData.accounts.filter(account => account.id !== source.id)
        : teamData.accounts;

      const destination = Faker.random.arrayElement(destinations);

      return {
        amount: 5000,
        source: source ? source.id : null,
        destination: destination ? destination.id : null,
        description: 'Simple Pmt',
      };
    }
    default: {}
  }
}
