import * as React from 'react';
import _ from 'lodash';
import Faker from 'faker';
import {
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiFieldText,
  EuiFlexItem,
  EuiText,
  EuiSuperSelect,
  EuiAvatar,
  EuiSelectable,
  EuiHighlight,
  EuiBadge,
  EuiLink,
  EuiToolTip,
  EuiCard,
  EuiPopover,
} from '@elastic/eui';

import * as ResourceEnums from '../../../../enums/Resources';
import * as utils from '../../../../utils';
import * as Modals from '../../../Modals';
import * as EmptyStates from '../../../EmptyStates';


function Accounts(props) {
  // State
  const { isLoading, onSubmit, onUpdateForm, requestData, responseData, teamData, validationErrors, onRefreshTeamData, proMode } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLiabPopoverOpen, setIsLiabPopoverOpen] = React.useState(false);
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const _updateForm = update => onUpdateForm({ ...requestData, ...update });
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [isSearchingMerchants, setIsSearchingMerchants] = React.useState(false);
  const [merchantOptions, setMerchantOptions] = React.useState([]);
  const merchantSample = extractableTeam.getMerchantSample(requestData.merchant);
  const previousEntities = utils.hooks.usePrevious(teamData.entities);
  const isMobile = utils.hooks.useIsMobile();
  const _onSubmit = () => {
    if (validationErrors.length > 0) {
      setSubmitAttempted(true);
    } else {
      onSubmit();
      setSubmitAttempted(false);
    }
  };
  const sortMerchantOptions = merchants => _.orderBy(merchants, ({ merchant }) => extractableTeam.getBadgesForResource(merchant, ResourceEnums.ResourceTypes.Merchant.key), ['desc']);
  const renderMerchant = ({ label, merchant }, searchValue) => {
    const badges = extractableTeam.getBadgesForResource(merchant, ResourceEnums.ResourceTypes.Merchant.key)
    return (
      <React.Fragment>
        <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar name={merchant.name} imageUrl={merchant.logo} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiHighlight search={searchValue}>{label}</EuiHighlight>
            <EuiFlexGroup alignItems="center" gutterSize="none" responsive={false}>
              {badges.map(badge => (
                <EuiBadge color={badge.color} key={`${merchant.mch_id}-${badge.text}`}>
                  <small><b>{badge.text}</b></small>
                </EuiBadge>
              ))}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </React.Fragment>
    );
  };
  React.useEffect(() => {
    setSubmitAttempted(false);
  }, [requestData.type]);
  React.useEffect(() => {
    let formattedOptions = teamData.merchants.map(merchant => ({
      mch_id: merchant.mch_id,
      label: merchant.name,
      merchant,
    }));

    if (requestData.merchant) {
      formattedOptions = merchantOptions.map(x => ({ ...x, checked: x.mch_id === requestData.merchant ? 'on' : null }));
      setIsSearchingMerchants(false);
    }

    setMerchantOptions(sortMerchantOptions(formattedOptions));
  }, [teamData.merchants, requestData.merchant]); // eslint-disable-line
  React.useEffect(() => {
    if (previousEntities && previousEntities.length !== teamData.entities.length) {
      const newEntity = teamData.entities.filter(entity => !previousEntities.some(x => x.id === entity.id))[0];
      if (newEntity) _updateForm({ holder: newEntity.id });
    }
  }, [teamData.entities]); // eslint-disable-line
  // Open modal
  if (isModalOpen) return <Modals.CreateEntity open onClose={closeModal} teamData={teamData} onRefreshTeamData={onRefreshTeamData} />;
  // Empty entities
  if (teamData.entities.length === 0) return <EmptyStates.ZeroEntities onContinue={openModal} />;
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiForm component="form">
          {/* holder */}
          <EuiFormRow
            label="Owner of the account"
            fullWidth
            isInvalid={submitAttempted && validationErrors.includes('holder_id')}
            labelAppend={!proMode && (
              <EuiText size="xs">
                <EuiLink disabled={responseData} onClick={openModal}>
                  + Create an entity
                </EuiLink>
              </EuiText>
            )}>
            <EuiSuperSelect
              hasDividers
              fullWidth
              itemLayoutAlign="top"
              disabled={responseData}
              valueOfSelected={requestData.holder || ''}
              isInvalid={submitAttempted && validationErrors.includes('holder_id')}
              onChange={holder => _updateForm({ holder })}
              prepend={
                requestData.holder
                  ? extractableTeam.getEntity(requestData.holder).type === ResourceEnums.EntityTypes.Individual.key
                  ? ResourceEnums.EntityTypes.Individual.emoji
                  : ResourceEnums.EntityTypes.CCorporation.emoji
                  : null
              }
              options={
                teamData.entities.map((entity) => ({
                  value: entity.id,
                  inputDisplay: extractableTeam.toPresentableEntity(entity),
                  dropdownDisplay: (
                    <React.Fragment>
                      <strong>
                        {entity.type === ResourceEnums.EntityTypes.Individual.key
                          ? ResourceEnums.EntityTypes.Individual.emoji
                          : ResourceEnums.EntityTypes.CCorporation.emoji
                        }
                        {' '}
                        {extractableTeam.toPresentableEntity(entity)}
                      </strong>
                      <EuiFlexGroup alignItems="center" gutterSize="none" responsive={false}>
                        {extractableTeam.getBadgesForResource(entity, ResourceEnums.ResourceTypes.Entities.key).map(badge => (
                          <EuiBadge color={badge.color} key={`${entity.id}-${badge.text}`}>
                            <small><b>{badge.text}</b></small>
                          </EuiBadge>
                        ))}
                      </EuiFlexGroup>
                    </React.Fragment>
                  ),
                }))
              } />
          </EuiFormRow>

          {/* type */}
          {requestData.holder && (
            <EuiFormRow label="Type" fullWidth isInvalid={submitAttempted && validationErrors.includes('type')}>
              {proMode ? (
                <EuiSuperSelect
                  hasDividers
                  fullWidth
                  itemLayoutAlign="top"
                  disabled={responseData}
                  valueOfSelected={requestData.type || ''}
                  isInvalid={submitAttempted && validationErrors.includes('type')}
                  onChange={type => _updateForm({ type, number: '', routing: '' })}
                  prepend={
                    requestData.type
                      ? requestData.type === ResourceEnums.AccountTypes.ACH.key
                      ? ResourceEnums.AccountTypes.ACH.emoji
                      : ResourceEnums.AccountTypes.Liability.emoji
                      : null
                  }
                  options={
                    Object.values(ResourceEnums.AccountTypes).map((accountType) => ({
                      value: accountType.key,
                      inputDisplay: accountType.presentable,
                      dropdownDisplay: (
                        <React.Fragment>
                          <strong>{accountType.emoji} {accountType.presentable}</strong>
                          <EuiText size="s" color="subdued">
                            <p className="euiTextColor--subdued">
                              {accountType.key === 'ach' ? 'Checking or savings account' : 'Student loan, credit card, mortgage, etc.'}
                            </p>
                          </EuiText>
                        </React.Fragment>
                      ),
                    }))
                  } />
              ) : (
                <EuiFlexGroup style={{ paddingTop: 4 }} responsive={isMobile}>
                  <EuiFlexItem>
                    <EuiCard
                      layout="horizontal"
                      titleSize="xs"
                      title={[ResourceEnums.AccountTypes.ACH.emoji, ResourceEnums.AccountTypes.ACH.presentable].join(' ')}
                      paddingSize="m"
                      description="Checking or savings account."
                      selectable={{
                        onClick: () => _updateForm({ type: ResourceEnums.AccountTypes.ACH.key }),
                        isSelected: requestData.type === ResourceEnums.AccountTypes.ACH.key,
                        isDisabled: Boolean(responseData),
                      }} />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiPopover
                      display="block"
                      anchorPosition="upCenter"
                      isOpen={isLiabPopoverOpen}
                      closePopover={() => setIsLiabPopoverOpen(false)}
                      button={<EuiCard
                        layout="horizontal"
                        titleSize="xs"
                        title={[ResourceEnums.AccountTypes.Liability.emoji, ResourceEnums.AccountTypes.Liability.presentable].join(' ')}
                        paddingSize="m"
                        description="Student loan, credit card, mortgage, etc."
                        selectable={{
                          onClick: () => setIsLiabPopoverOpen(true),
                          isSelected: requestData.type === ResourceEnums.AccountTypes.Liability.key,
                          isDisabled: Boolean(responseData),
                        }} />}>
                      <EuiButton size="s" onClick={() => setIsLiabPopoverOpen(false) || props.onOpenLink({ withPlaid: true })}>With Plaid</EuiButton>
                      <EuiButton size="s" onClick={() => setIsLiabPopoverOpen(false) || props.onOpenLink()}>Without Plaid</EuiButton>
                    </EuiPopover>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
            </EuiFormRow>
          )}
          {/* ach */}
          {requestData.holder && requestData.type === ResourceEnums.AccountTypes.ACH.key && [
            <EuiFormRow key="achType" fullWidth label="ACH account type" isInvalid={submitAttempted && validationErrors.includes('ach.type')}>
              {proMode ? (
                <EuiSuperSelect
                  hasDividers
                  fullWidth
                  itemLayoutAlign="top"
                  disabled={responseData}
                  valueOfSelected={requestData.achType || ''}
                  isInvalid={submitAttempted && validationErrors.includes('ach.type')}
                  onChange={achType => _updateForm({ achType })}
                  prepend={
                    requestData.achType
                      ? requestData.achType === ResourceEnums.AccountACHTypes.Checking.key
                      ? ResourceEnums.AccountACHTypes.Checking.emoji
                      : ResourceEnums.AccountACHTypes.Savings.emoji
                      : null
                  }
                  options={
                    Object.values(ResourceEnums.AccountACHTypes).map((accountACHType) => ({
                      value: accountACHType.key,
                      inputDisplay: accountACHType.presentable,
                      dropdownDisplay: (
                        <React.Fragment>
                          <strong>{accountACHType.emoji} {accountACHType.presentable}</strong>
                        </React.Fragment>
                      ),
                    }))
                  } />
              ) : (
                <EuiFlexGroup style={{ paddingTop: 4 }} responsive={isMobile}>
                  <EuiFlexItem>
                    <EuiCard
                      layout="horizontal"
                      titleSize="xs"
                      description="Connect a checking account."
                      title={[ResourceEnums.AccountACHTypes.Checking.emoji, ResourceEnums.AccountACHTypes.Checking.presentable].join(' ')}
                      paddingSize="m"
                      selectable={{
                        onClick: () => _updateForm({ achType: ResourceEnums.AccountACHTypes.Checking.key }),
                        isSelected: requestData.achType === ResourceEnums.AccountACHTypes.Checking.key,
                        isDisabled: Boolean(responseData),
                      }} />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiCard
                      layout="horizontal"
                      titleSize="xs"
                      description="Connect a savings account."
                      title={[ResourceEnums.AccountACHTypes.Savings.emoji, ResourceEnums.AccountACHTypes.Savings.presentable].join(' ')}
                      paddingSize="m"
                      selectable={{
                        onClick: () => _updateForm({ achType: ResourceEnums.AccountACHTypes.Savings.key }),
                        isSelected: requestData.achType === ResourceEnums.AccountACHTypes.Savings.key,
                        isDisabled: Boolean(responseData),
                      }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
            </EuiFormRow>,
            <EuiFormRow
              fullWidth
              key="routing"
              label="Routing Number"
              isInvalid={submitAttempted && validationErrors.includes('ach.routing')}
              labelAppend={
                <EuiText size="xs">
                  <EuiLink disabled={responseData} onClick={() => _updateForm({ routing: Faker.finance.routingNumber() })}>
                    Use test routing number
                  </EuiLink>
                </EuiText>
              }>
              <EuiFieldText fullWidth disabled={responseData} maxLength={9} value={requestData.routing} isInvalid={submitAttempted && validationErrors.includes('ach.routing')} placeholder="021000021" onChange={e => _updateForm({ routing: e.target.value })} />
            </EuiFormRow>,
            <EuiFormRow
              fullWidth
              key="account_number"
              label="Account Number"
              isInvalid={submitAttempted && validationErrors.includes('ach.number')}
              labelAppend={
                <EuiText size="xs">
                  <EuiLink disabled={responseData} onClick={() => _updateForm({ number: Faker.finance.account() })}>
                    Use test account number
                  </EuiLink>
                </EuiText>
              }>
              <EuiFieldText fullWidth disabled={responseData} value={requestData.number} isInvalid={submitAttempted && validationErrors.includes('ach.number')} placeholder="2123618811" onChange={e => _updateForm({ number: e.target.value })} />
            </EuiFormRow>,
          ]}

          {/* liability */}
          {requestData.holder && requestData.type === ResourceEnums.AccountTypes.Liability.key && [
            <EuiFormRow
              key="merchant"
              fullWidth
              label="Merchant"
              isInvalid={submitAttempted && validationErrors.includes('liability.mch_id')}
              labelAppend={
                <EuiText size="xs">
                  <EuiToolTip position="top" content="💡 The demo only contains some of the most popular merchants. Method supports the majority of student loan, credit card, and mortgage merchants out there.">
                    <EuiLink>Can't find a merchant?</EuiLink>
                  </EuiToolTip>
                </EuiText>
              }>
              <EuiSelectable
                searchable
                singleSelection="always"
                options={merchantOptions}
                onChange={(updatedMerchantOptions) => {
                  const selectedMerchant = updatedMerchantOptions.find(({ checked }) => checked);
                  if (selectedMerchant) {
                    _updateForm({ merchant: selectedMerchant.mch_id, number: '' });
                    setIsSearchingMerchants(false);
                  }

                  setMerchantOptions(sortMerchantOptions(updatedMerchantOptions));
                }}
                searchProps={{
                  prepend: '🏬',
                  value: (isSearchingMerchants || !requestData.merchant)
                    ? null
                    : (merchantOptions.find(({ checked }) => checked))
                      ? merchantOptions.find(({ checked }) => checked).merchant.name
                      : null,
                  placeholder: 'Search merchants',
                  disabled: Boolean(responseData),
                  isInvalid: submitAttempted && validationErrors.includes('liability.mch_id'),
                  onFocus: () => setIsSearchingMerchants(true),
                  onSearch: () => !isSearchingMerchants && setIsSearchingMerchants(true),
                  onBlur: () => setTimeout(() => setIsSearchingMerchants(false), 300),
                }}
                listProps={{ showIcons: false, rowHeight: 73 }}
                renderOption={renderMerchant}>
                {(list, search) => (
                  <React.Fragment>
                    {search}
                    {isSearchingMerchants ? list : null}
                  </React.Fragment>
                )}
              </EuiSelectable>
            </EuiFormRow>,
            <EuiFormRow
              fullWidth
              key="account_number"
              label="Account Number"
              isInvalid={submitAttempted && validationErrors.includes('liability.account_number')}
              labelAppend={
                <EuiText size="xs">
                  {merchantSample
                    ? <EuiLink disabled={responseData} onClick={() => _updateForm({ number: merchantSample.account_number })}>Use test account number</EuiLink>
                    : (
                      <EuiToolTip position="top" content="Valid account number required to create an account for this merchant. Method validates all account numbers with the merchant to lower the risk of failed/returned payments.">
                        <EuiLink>Test account number unavailable</EuiLink>
                      </EuiToolTip>
                    )
                  }
                </EuiText>
              }>
              <EuiFieldText fullWidth disabled={responseData} value={requestData.number} isInvalid={submitAttempted && validationErrors.includes('liability.account_number')} onChange={e => _updateForm({ number: e.target.value })} />
            </EuiFormRow>,
          ]}
        </EuiForm>
      </EuiFlexItem>
      <EuiFlexItem>
        {/* submit */}
        <EuiButton fill fullWidth isDisabled={Boolean(isLoading || responseData)} isLoading={isLoading} onClick={_onSubmit}>Create Account</EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default Accounts;
