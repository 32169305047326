import * as React from 'react';
import {
  EuiStepsHorizontal,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiPanel,
  EuiEmptyPrompt,
  EuiSpacer,
  EuiButton,
  EuiButtonIcon,
  EuiCodeBlock,
  EuiPageContentBody,
  EuiPageBody,
  EuiShowFor,
  EuiButtonEmpty,
  EuiToolTip,
  EuiLink
} from '@elastic/eui';

import * as ResourcesEnums from '../../../enums/Resources';
import * as Steps from './Steps';
import * as HOCs from '../../HOCs';
import * as api from '../../../api';
import * as utils from '../../../utils';


function Wizard(props) {
  const { teamData, onRefreshTeamData, setCodeBlockModalState } = props;

  // State
  const [currentStep, setCurrentStep] = React.useState(0);
  const [resources, setResources] = React.useState(utils.helpers.getInitialResourcesState());
  const [isLoading, setIsLoading] = React.useState(false);
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);
  const currentResource = resources[currentStep];
  const Component = Steps[currentResource.key];
  const formattedPayload = utils.helpers.formatCreateRequestPayload(currentResource.key, currentResource.requestData);
  const payloadValidation = utils.validators.validatePayload(currentResource.key, formattedPayload);
  const validationErrors = payloadValidation.error ? payloadValidation.error.details.map(x => x.context.label) : [];

  // Methods
  const _updateResource = (key, update) => setResources(resources.map(resource => resource.key !== key ? resource : { ...resource, ...update }));
  const _clearResource = (key) => setResources(resources.map(resource => resource.key !== key ? resource : { ...resource, requestData: {}, responseData: null }));
  const _getResource = key => resources.find(resource => resource.key === key);
  const onUpdateForm = key => requestData => _updateResource(key, { responseData: null, requestData });
  const nextStep = () => setCurrentStep(currentStep + 1);
  const hasNextStep = () => (currentStep + 1 < resources.length) && currentResource.responseData !== null;
  const onSubmit = (key) => async () => {
    const { requestData } = _getResource(key);
    const payload = utils.helpers.formatCreateRequestPayload(key, requestData);
    try {
      setIsLoading(true);
      _updateResource(key, { responseData: await api.createResource(key, payload) });
      onRefreshTeamData();
      document.getElementById('result-panel').scrollIntoView();
    } catch (error) {
      props.addToast({
        title: 'Oops, there was an error',
        color: 'danger',
        iconType: 'alert',
        text: <p>{error.response.data.message}</p>,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const contentStyle = utils.hooks.useIsMobile() ? { width: '100%' } : { minWidth: 375, width: 500 };
  const content = [
    <EuiFlexItem key="wizard-form" grow style={contentStyle}>
      <Component
        key={currentResource.key}
        requestData={currentResource.requestData}
        responseData={currentResource.responseData}
        teamData={teamData}
        onUpdateForm={onUpdateForm(currentResource.key)}
        onSubmit={onSubmit(currentResource.key)}
        isLoading={isLoading}
        validationErrors={validationErrors}
        onRefreshTeamData={onRefreshTeamData}
        proMode={true} />
    </EuiFlexItem>,
    <EuiFlexItem key="wizard-result" grow style={contentStyle}>
      <EuiCodeBlock
        language="bash"
        fontSize="m"
        paddingSize="m"
        color="dark"
        overflowHeight={500}
        whiteSpace="pre"
        isCopyable>
        {[
          `curl https://dev.methodfi.com/${currentResource.key.toLowerCase()} \\`,
          '    -X POST \\',
          `    -H "Authorization: Bearer ${utils.url.getToken()}" \\`,
          '    -H "Content-Type: application/json" \\',
          `    -d '${JSON.stringify(utils.helpers.formatCreateRequestPayload(currentResource.key, currentResource.requestData), null, 2)}'`,
        ].join('\n')}
      </EuiCodeBlock>
    </EuiFlexItem>,
  ];

  const height = utils.hooks.useIsMobile() ? '110vh' : '100vh';

  return (
    <EuiPageBody panelled style={{ minHeight: height, maxHeight: height, overflowY: 'auto' }}>
      <EuiPageContentBody restrictWidth="100%">
        <EuiSpacer size="xxl" />
        <EuiSpacer size="m" />
        {/* Main Header */}
        <EuiText textAlign="center" size="s">
          <h1>🏦</h1>
          <h1>Method Demo</h1>
        </EuiText>
        {/* Steps */}
        <EuiStepsHorizontal steps={resources.map((resource, idx) => ({
          title: resource.title,
          isSelected: idx === currentStep,
          onClick: () => {},
          isComplete: Boolean(idx < currentStep || (idx === currentStep && currentResource.responseData)),
        }))} />
        <EuiSpacer size="xxl" />
        {/* Step Header + Auto Fill */}
        <EuiPageHeader
          pageTitle={<EuiText size="s"><h1>{currentResource.emoji} {currentResource.title}</h1></EuiText>}
          description={
            <EuiText size="s">
              <p><b>{currentResource.description}{' '}</b>
                <EuiToolTip position="top" content={currentResource.tooltipHoverContent}>
                  <EuiLink href="#">{currentResource.tooltipPresentedText}</EuiLink>
                </EuiToolTip>
              </p>
            </EuiText>
          }
          rightSideItems={[
            <EuiButtonEmpty target="_blank" href={currentResource.docsURL}>
              View API reference
            </EuiButtonEmpty>,
            <EuiButton
              fill
              iconType="indexEdit"
              isDisabled={Boolean(currentResource.responseData)}
              onClick={() => onUpdateForm(currentResource.key)(utils.autofill(currentResource.key, teamData))}>
              Fill with sample data
            </EuiButton>
          ]} />
        <EuiSpacer />
        {/* Step Content */}
        <EuiShowFor sizes={['xs', 's']}>
          <EuiFlexGroup direction="column">
            {content}
          </EuiFlexGroup>
        </EuiShowFor>

        <EuiShowFor sizes={['m', 'l', 'xl']}>
          <EuiFlexGroup>
            {content}
          </EuiFlexGroup>
        </EuiShowFor>

        <EuiSpacer />
        {currentResource.responseData && (
          <EuiPanel hasBorder paddingSize="l" id="result-panel">
            <EuiEmptyPrompt
              iconType="usersRolesApp"
              iconColor={null}
              title={<h2>{currentResource.resFooterTitle}</h2>}
              titleSize="s"
              actions={currentResource.responseData && (
                <EuiFlexGroup responsive={false} gutterSize="s" justifyContent="center" alignItems="center">
                  {hasNextStep() && (
                    <EuiFlexItem grow={false}>
                      <EuiButton fill size="s" onClick={() => nextStep()}>
                        Next
                      </EuiButton>
                    </EuiFlexItem>
                  )}
                  <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                      display="base"
                      size="s"
                      iconType="indexOpen"
                      aria-label="More"
                      onClick={() => _clearResource(currentResource.key)} />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                      display="base"
                      size="s"
                      iconType="console"
                      aria-label="More"
                      onClick={() => {
                        const subtitle = ({
                          [ResourcesEnums.ResourceTypes.Entities.key]: extractableTeam.toPresentableEntity,
                          [ResourcesEnums.ResourceTypes.Accounts.key]: extractableTeam.toPresentableAccount,
                          [ResourcesEnums.ResourceTypes.Payments.key]: extractableTeam.toPresentablePayment,
                        })[currentResource.key](currentResource.responseData);

                        setCodeBlockModalState({
                          open: true,
                          title: currentResource.presentableSingular,
                          subtitle,
                          requestType: 'GET',
                          resourceType: currentResource.key,
                          resource: currentResource.responseData,
                        });
                      }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
              body={
                <p>
                  {currentResource.resFooterBody}
                </p>
              } />
          </EuiPanel>
        )}
      </EuiPageContentBody>
    </EuiPageBody>
  );
}

export default HOCs.withGlobalToastList(Wizard);
