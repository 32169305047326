import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiText,
  EuiListGroup,
  EuiListGroupItem,
  EuiBadge,
  EuiButton,
  EuiModalFooter,
  EuiSpacer,
  EuiAvatar,
} from '@elastic/eui';
import * as utils from '../../utils';


function SelectAccount(props) {
  // State
  const { open, onClose, onSelect, teamData, accounts, title, subtitle, onOpenCreateAccount } = props;
  const _onSelect = (account) => {
    onClose();
    onSelect(account);
  };
  const _onOpenCreateAccount = () => {
    onClose();
    onOpenCreateAccount();
  };
  if (!open) return null;
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);
  return (
    <EuiModal maxWidth={400} onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {title}
          {subtitle && <><EuiSpacer size="xs" /><EuiText size="s"><p>{subtitle}</p></EuiText></>}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiListGroup gutterSize="s">
          {accounts.map((account) => {
            const panelDetails = extractableTeam.getAccountPanelDetails(account.id);
            return (
              <EuiListGroupItem
                wrapText
                onClick={() => _onSelect(account.id)}
                key={account.id}
                size="s"
                extraAction={{ color: 'subdued', iconType: 'arrowRight', iconSize: 's' }}
                icon={
                  panelDetails.image
                    ? <EuiAvatar size="l" name={panelDetails.image} imageUrl={panelDetails.image} />
                    : <EuiAvatar size="l" type="space" name={panelDetails.emoji} initialsLength={2} color="#dcdde1" />
                }
                label={
                  <>
                    <p>{panelDetails.title}</p>
                    {panelDetails.badges.map(badge => (
                      <EuiBadge key={`${account.id}-${badge.text}`} color={badge.color}>
                        <small><b>{badge.text}</b></small>
                      </EuiBadge>
                    ))}
                  </>
                } />
            );
          })}
        </EuiListGroup>
      </EuiModalBody>
      <EuiSpacer />
      <EuiModalFooter>
        <EuiButton fullWidth fill onClick={_onOpenCreateAccount}>
          Create new account
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default SelectAccount;
