import * as React from 'react';
import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageBody,
  EuiPageContent,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { useMethodLink } from 'react-method-link';

import * as HOCs from '../../HOCs';
import * as utils from '../../../utils';
import * as api from '../../../api';
import * as ResourcesEnums from '../../../enums/Resources';
import HoistPlaidLinkHook from "../../HoistPlaidLinkHook";
import {AccountTypes} from "../../../enums/Resources";



function Flow(props) {
  const { onRefreshTeamData, teamData, setCodeBlockModalState } = props;
  const resourceKey = ResourcesEnums.ResourceTypes.Accounts.key;
  const resourceState = utils.helpers.getInitialResourcesState().filter(({ key }) => key === resourceKey)[0];
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);
  const firstEntity = teamData.entities[0];
  const [requestData, setRequestData] = React.useState({ holder: firstEntity.id });
  const [responseData, setResponseData] = React.useState(null);
  const formattedPayload = utils.helpers.formatCreateRequestPayload(resourceKey, requestData);
  const openSuccessToastForAccount = (account) => {
    const accountName = extractableTeam.toPresentableAccount(account);
    props.addToast({
      title: 'New account created',
      color: 'success',
      text: <p>{accountName} is now ready to be used.</p>,
    })
  };
  const openErrorToastWithError = (error) => {
    props.addToast({
      title: 'Oops, there was an error',
      color: 'danger',
      iconType: 'alert',
      text: <p>{error.response.data.message}</p>,
    });
  };
  const openErrorToastForMissingMerchant = (merchantName) => {
    props.addToast({
      title: 'Unsupported merchant',
      color: 'warning',
      iconType: 'alert',
      text: <p>Unfortunately {merchantName} is currently not supported by Method.</p>,
    });
  };

  const onSubmit = async (overwritePayload) => {
    try {
      const res = await api.createResource(resourceKey, { ...formattedPayload, ...(overwritePayload || {}) });
      openSuccessToastForAccount(res);
      setResponseData(res);
      onRefreshTeamData();
      document.getElementById('result-panel').scrollIntoView();
    } catch (error) {
      openErrorToastWithError(error);
    }
  };
  const clearResource = () => {
    setRequestData({ holder: firstEntity.id });
    setResponseData(null);
  };

  const isMobile = utils.hooks.useIsMobile();
  const height = isMobile ? 'calc(100vh - 48px)' : '100%';
  const onViewAPIResource = () => {
    setCodeBlockModalState({
      open: true,
      title: 'Account',
      subtitle: extractableTeam.toPresentableAccount(responseData),
      resourceType: ResourcesEnums.ResourceTypes.Accounts.key,
      requestType: 'GET',
      resource: responseData,
    });
  };

  const [plaidLinkToken, setPlaidLinkToken] = React.useState(null);

  const { openWithToken } = useMethodLink({
    env: 'dev',
    onSuccess: data => api.exchangeLinkToken(data)
      .then(setResponseData)
      .catch(openErrorToastWithError)
      .finally(onRefreshTeamData),
  });

  const initializeFlow = () => api.fetchPlaidLinkToken({ token: utils.url.getToken(), team_name: teamData.team.name }).then(setPlaidLinkToken);

  return (
    <EuiPageBody paddingSize="l" style={{ paddingTop: 48, paddingLeft: 0, paddingRight: 0, minHeight: height, maxHeight: height }}>
      <EuiPageContent
        verticalPosition="center"
        horizontalPosition="center"
        style={{ width: 450, marginTop: isMobile ? 0 : 48, paddingTop: 48 }}>
        <EuiText>
          <h3>🌟 Plaid + Method Flow</h3>
        </EuiText>
        <EuiSpacer />
        {!responseData && <EuiButton fullWidth onClick={initializeFlow}>Launch Flow</EuiButton>}

        {plaidLinkToken && (
          <HoistPlaidLinkHook
            onOpen={() => {}}
            config={{
              token: plaidLinkToken,
              onSuccess: () => {},
              onExit: () => setPlaidLinkToken(''),
              onEvent: (type, data) => {
                if (type === 'HANDOFF') {
                  const merchant = extractableTeam.getMerchantWithPlaidId(data.institution_id)
                  if (!merchant) {
                    openErrorToastForMissingMerchant(data.institution_name);
                    return setPlaidLinkToken('');
                  }
                  const [merchant_type] = merchant.types;
                  switch (merchant_type) {
                    case 'student_loan':
                      onSubmit({
                        holder_id: requestData.holder,
                        liability: { mch_id: merchant.mch_id, account_number: '9832723822' },
                      });
                      break;
                    default:
                      api.fetchLinkToken({
                        entity_id: requestData.holder,
                        mch_id: merchant.mch_id,
                        mask: '3023',
                      }).then(openWithToken);
                      break;
                  }
                  setPlaidLinkToken('');
                }
              },
            }} />
        )}

        {responseData && <EuiSpacer />}

        {responseData && (
          <EuiPanel hasBorder paddingSize="l" id="result-panel">
            <EuiEmptyPrompt
              iconType="usersRolesApp"
              iconColor={null}
              title={<h2>{resourceState.resFooterTitle}</h2>}
              titleSize="s"
              body={<p>{resourceState.resFooterBody}</p>}
              actions={(
                <EuiFlexGroup responsive={false} gutterSize="s" justifyContent="center" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiButton display="base" size="s" iconType="console" iconSide="right" aria-label="More" onClick={onViewAPIResource}>
                      See API response
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton display="base" size="s" fill iconType="indexOpen" iconSide="right" aria-label="More" onClick={clearResource}>
                      Create another
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}/>
          </EuiPanel>
        )}

      </EuiPageContent>

    </EuiPageBody>
  );
}

export default HOCs.withGlobalToastList(Flow);
