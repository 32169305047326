import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiForm,
  EuiCheckbox,
  EuiFormRow,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiText,
  EuiSpacer,
} from '@elastic/eui';

import * as api from '../../api';
import * as utils from '../../utils';


function GetStarted(props) {
  // State
  const { open } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setTeam] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [optedIn, setOptedIn] = React.useState(false);
  const teamValidation = utils.validators.validateTeamPayload(optedIn ? { name, email } : { name });
  const validationErrors = teamValidation.error ? teamValidation.error.details.map(x => x.context.label) : [];
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  // Methods
  const onSubmit = () => {
    if (validationErrors.length > 0) {
      setSubmitAttempted(true);
    } else {
      setIsLoading(true);
      const teamPayload = { name };
      if (optedIn) teamPayload.email = email;

      // TODO: handle error
      api.createTeam(teamPayload)
        .then((token) => {
          utils.helpers.setShouldWelcome(token);
          // Redirect with token
          window.location.href = `${window.location.origin}?tok=${token}`;
          // hash email and
        })
        .catch(console.log)
        .finally(() => setIsLoading(false));

      setSubmitAttempted(false);
    }
  };

  if (!open) return null;

  return (
    <EuiModal maxWidth={400} onClose={() => {}}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          👋 Welcome to Method!
          <EuiSpacer size="s"/>
          <EuiText size="s">
            <p>This a demo of Method's debt-repayment and bank transfer APIs. To get started, enter your company name below.</p>
          </EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm>
          <EuiFormRow fullWidth label="Your company name" isInvalid={submitAttempted && validationErrors.includes('name')}>
            <EuiFieldText fullWidth value={name} isInvalid={submitAttempted && validationErrors.includes('name')} placeholder="Alphabet Inc." onChange={e => setTeam(e.target.value)} />
          </EuiFormRow>
          {optedIn && (
            <EuiFormRow fullWidth label="Your work email" isInvalid={submitAttempted && validationErrors.includes('email')}>
              <EuiFieldText fullWidth value={email} isInvalid={submitAttempted && validationErrors.includes('email')} placeholder="sundar@abc.xyz" onChange={e => setEmail(e.target.value)} />
            </EuiFormRow>
          )}
          <EuiFormRow>
            <EuiCheckbox
              id="checkbox"
              label="Would you like to chat with our team?"
              checked={optedIn}
              onChange={(e) => {
                setOptedIn(e.target.checked);
                setSubmitAttempted(false);
              }} />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton fill fullWidth isLoading={isLoading} onClick={onSubmit}>Start Demo 🚀</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default GetStarted;
