import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiText,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiCodeBlock,
  EuiSpacer,
  EuiCode
} from '@elastic/eui';
import * as utils from '../../utils';

const token = utils.url.getToken();

function CodeBlock(props) {
  // State
  const { title, subtitle, open, onClose, resourceType, resource, requestType } = props;

  if (!open) return null;

  let requestStr;

  if (requestType === 'GET') {
    requestStr = [
      `curl https://dev.methodfi.com/${resourceType.toLowerCase()}/${resource.id} \\`,
      `    -H "Authorization: Bearer ${token}"`,
    ].join('\n');
  }

  if (requestType === 'POST') {
    requestStr = [
      `curl https://dev.methodfi.com/${resourceType.toLowerCase()} \\`,
      '    -X POST \\',
      `    -H "Authorization: Bearer ${token}" \\`,
      '    -H "Content-Type: application/json" \\',
      `    -d '${JSON.stringify(utils.helpers.formatCreateRequestPayloadFromResponse(resourceType, resource), null, 2)}'`,
    ].join('\n');
  }

  const responseStr = JSON.stringify(resource, null, 2);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiText>
          <h2>
            {title}
          </h2>
          {subtitle} <EuiCode>{resource.id}</EuiCode>
        </EuiText>
      </EuiModalHeader>

      <EuiSpacer size="s" />

      <EuiModalBody>
        <p><strong>Request</strong></p>
        <EuiSpacer size="s" />
        <EuiCodeBlock language={'bash'} fontSize="m" paddingSize="m" color="dark" whiteSpace="pre" isCopyable>
          {requestStr}
        </EuiCodeBlock>

        <EuiSpacer />

        <p><strong>Response</strong></p>
        <EuiSpacer size="s" />
        <EuiCodeBlock language={'bash'} fontSize="m" paddingSize="m" color="dark" whiteSpace="pre" isCopyable>
          {responseStr}
        </EuiCodeBlock>

      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton fullWidth color="text" onClick={onClose}>Close</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default CodeBlock;
