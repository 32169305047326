import Joi from 'joi';
import * as ResourcesEnums from '../enums/Resources';


const addressSchema = Joi.object().keys({
  line1: Joi.string().required(),
  line2: Joi.any(),
  city: Joi.string().required(),
  state: Joi.string().min(2).required(),
  zip: Joi.string().min(5).required(),
}).required();

const corpOwnerSchema = Joi.object().keys({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  phone: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false }, minDomainSegments: 2 }).required(),
  dob: Joi.string().regex(/^\d{4}-\d{2}-\d{2}$/).required(),
  address: addressSchema,
}).required();

const personSchema = Joi.object().keys({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  phone: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false }, minDomainSegments: 2 }).required(),
  dob: Joi.string().regex(/^\d{4}-\d{2}-\d{2}$/).required(),
}).required();

const corporationSchema = Joi.object().keys({
  name: Joi.string().required(),
  ein: Joi.string().regex(/^\d{9}$/).required(),
  owners: Joi.array(),
}).required();

const achSchema = Joi.object().keys({
  routing: Joi.string().regex(/^\d{9}$/).required(),
  number: Joi.string().required(),
  type: Joi.string().valid(ResourcesEnums.AccountACHTypes.Savings.key, ResourcesEnums.AccountACHTypes.Checking.key).required(),
}).required();

const liabilitySchema = Joi.object().keys({
  mch_id: Joi.string().required(),
  account_number: Joi.string().required(),
}).required();

const paymentSchema = Joi.object().keys({
  amount: Joi.number().greater(99).required(),
  source: Joi.string().required(),
  destination: Joi.string().required(),
  description: Joi.string().max(10).required(),
}).required();

const teamSchema = Joi.object().keys({
  name: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false }, minDomainSegments: 2 }),
}).required();

export function validateEntityPayload(entity) {
  const schemaObj = {};

  schemaObj.type = Joi.string().valid(...Object.values(ResourcesEnums.EntityTypes).map(x => x.key)).required();
  schemaObj.address = addressSchema;

  switch (entity.type) {
    case ResourcesEnums.EntityTypes.Individual.key: {
      schemaObj.individual = personSchema;
      break;
    }
    default: {
      schemaObj.corporation = corporationSchema;
      break;
    }
  }

  return Joi.object().keys(schemaObj).validate(entity, { abortEarly: false });
}

export function validateAccountPayload(account) {
  const schemaObj = {};

  schemaObj.holder_id = Joi.string().required()

  if (account.ach) schemaObj.ach = achSchema;
  else schemaObj.liability = liabilitySchema;

  return Joi.object().keys(schemaObj).validate(account, { abortEarly: false });
}

export function validatePaymentPayload(payment) {
  return paymentSchema.validate(payment, { abortEarly: false });
}

export function validateOwnerPayload(owner) {
  return corpOwnerSchema.validate(owner, { abortEarly: false });
}

export function validateTeamPayload(team) {
  return teamSchema.validate(team, { abortEarly: false });
}

export function validateEmail(email) {
  return Joi.object().keys({
    email: Joi.string().email({ tlds: { allow: false }, minDomainSegments: 2 }).required(),
  }).required().validate(email, { abortEarly: false });
}

export function validatePayload(resourceType, payload) {
  const validator = ({
    [ResourcesEnums.ResourceTypes.Entities.key]: validateEntityPayload,
    [ResourcesEnums.ResourceTypes.Accounts.key]: validateAccountPayload,
    [ResourcesEnums.ResourceTypes.Payments.key]: validatePaymentPayload,
  })[resourceType];

  return validator(payload);
}
