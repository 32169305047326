import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiText,
  EuiSpacer,
} from '@elastic/eui';

import * as utils from '../../utils';
import * as services from '../../services';
import * as HOCS from '../../components/HOCs';


function GetAPIKeys(props) {
  // State
  const { open, onClose } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const teamValidation = utils.validators.validateEmail({ email });
  const validationErrors = teamValidation.error ? teamValidation.error.details.map(x => x.context.label) : [];
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  // Methods
  const onSubmit = async () => {
    if (validationErrors.length > 0) {
      setSubmitAttempted(true);
    } else {
      setIsLoading(true);

      // TODO: text
      try {
        await services.slack.requestForAPIKeys(email);
        setSubmitAttempted(false);
        onClose();
        setEmail('');
        props.addToast({
          title: 'Awesome! We\'ll reach out to you soon.',
          color: 'success',
          text: <p>Thanks!</p>,
        });
      } catch (error) {
        props.addToast({
          title: 'Oops, there was an error',
          color: 'danger',
          iconType: 'alert',
          text: <p>Please try that again.</p>,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!open) return null;

  // TODO: text
  return (
    <EuiModal maxWidth={400} onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          🚀 Skip the waitlist!
          <EuiSpacer size="s"/>
          <EuiText size="s">
            <p>Method is currently in private beta, but you can get started now. Enter your email and our team will reach out with some API keys.</p>
          </EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm>
          <EuiFormRow fullWidth label="Your work email" isInvalid={submitAttempted && validationErrors.includes('email')}>
            <EuiFieldText fullWidth value={email} isInvalid={submitAttempted && validationErrors.includes('email')} placeholder="sundar@abc.xyz" onChange={e => setEmail(e.target.value)} />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton fill fullWidth isLoading={isLoading} onClick={onSubmit}>Get Started</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default HOCS.withGlobalToastList(GetAPIKeys);
