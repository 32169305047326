import * as React from 'react';
import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiText,
  EuiIcon,
  EuiHideFor,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderLogo
} from '@elastic/eui';

import * as Modals from '../../Modals';


function Topbar(props) {
  const { teamData, toggleSidebar, proMode } = props;
  const [isGetAPIKeysModalOpen, setIsGetAPIKeysModalOpen] = React.useState(false);

  return (
    <EuiHeader position="fixed">

      <Modals.GetAPIKeys open={isGetAPIKeysModalOpen} onClose={() => setIsGetAPIKeysModalOpen(false)} />

      <EuiHeaderSection grow={false}>
        {proMode && (
          <EuiHeaderSectionItem>
            <EuiHideFor sizes={['xl']}>
              <EuiHeaderSectionItemButton onClick={toggleSidebar} notification>
                <EuiIcon type="apps" />
              </EuiHeaderSectionItemButton>
            </EuiHideFor>
          </EuiHeaderSectionItem>
        )}
        <EuiHeaderSectionItem>
          <EuiHeaderLogo
            iconType="https://uploads-ssl.webflow.com/5fc8640f295dcf4c80cf09ac/6049b1a9498f4461ef279a92_method-icon.svg"
            href="#"
            onClick={(e) => e.preventDefault()}
            aria-label="Go to home page">
            <EuiText><b>Method ❤️ {teamData.team.name}</b></EuiText>
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <EuiHeaderLinks popoverButtonProps={{ iconType: 'arrowDown' }}>

            <EuiHeaderLink target="_blank" iconType="apmTrace" href="https://docs.methodfi.com/">Documentation</EuiHeaderLink>

            <EuiHeaderLink iconType="beaker" isActive onClick={() => setIsGetAPIKeysModalOpen(true)}>Get API Keys</EuiHeaderLink>
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  );
}

export default Topbar;
