import * as React from 'react';
import {
  EuiButton,
  EuiSelect,
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiFieldText,
  EuiFlexItem,
  EuiSuperSelect,
  EuiCard,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';

import * as ResourceEnums from '../../../../enums/Resources';
import * as utils from '../../../../utils';


function Entities(props) {
  // State
  const { isLoading, onSubmit, onUpdateForm, requestData, responseData, validationErrors, teamData, proMode } = props;
  const _updateForm = update => onUpdateForm({ ...requestData, ...update });
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const isMobile = utils.hooks.useIsMobile();
  const _onSubmit = () => {
    if (validationErrors.length > 0) {
      setSubmitAttempted(true);
    } else {
      onSubmit();
      setSubmitAttempted(false);
    }
  };

  React.useEffect(() => {
    setSubmitAttempted(false);
  }, [requestData.type]);

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiForm component="form">
          {/* type */}
          <EuiFormRow label="Type" fullWidth isInvalid={submitAttempted && validationErrors.includes('type')}>
            {proMode ? (
              <EuiSuperSelect
                hasDividers
                fullWidth
                itemLayoutAlign="top"
                disabled={responseData}
                valueOfSelected={requestData.type || ''}
                isInvalid={submitAttempted && validationErrors.includes('type')}
                onChange={type => _updateForm({ type })}
                prepend={
                  requestData.type
                    ? requestData.type === ResourceEnums.EntityTypes.Individual.key
                    ? ResourceEnums.EntityTypes.Individual.emoji
                    : ResourceEnums.EntityTypes.CCorporation.emoji
                    : null
                }
                options={
                  Object.values(ResourceEnums.EntityTypes).map((entityType) => ({
                    value: entityType.key,
                    inputDisplay: entityType.presentable,
                    dropdownDisplay: (
                      <React.Fragment>
                        <strong>{entityType.emoji} {entityType.presentable}</strong>
                      </React.Fragment>
                    ),
                  }))
                } />
            ) : (
              <EuiFlexGroup style={{ paddingTop: 4 }} responsive={isMobile}>
                <EuiFlexItem>
                  <EuiCard
                    layout="horizontal"
                    titleSize="xs"
                    description="An individual account owner."
                    title={[ResourceEnums.EntityTypes.Individual.emoji, 'Person'].join(' ')}
                    paddingSize="m"
                    selectable={{
                      onClick: () => _updateForm({ type: ResourceEnums.EntityTypes.Individual.key }),
                      isSelected: requestData.type === ResourceEnums.EntityTypes.Individual.key,
                    }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiCard
                    layout="horizontal"
                    titleSize="xs"
                    description="A collective account owner."
                    title={[ResourceEnums.EntityTypes.CCorporation.emoji, 'Company'].join(' ')}
                    paddingSize="m"
                    selectable={{
                      onClick: () => _updateForm({ type: ResourceEnums.EntityTypes.CCorporation.key }),
                      isSelected: requestData.type === ResourceEnums.EntityTypes.CCorporation.key,
                    }} />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiFormRow>
          {!proMode && requestData.type && (
            <>
              <EuiSpacer size="l" />
              <EuiFormRow
                fullWidth
                children={<></>}
                label={(
                  <EuiText>
                    <h3>{requestData.type === ResourceEnums.EntityTypes.Individual.key ? 'Person' : 'Company'} Info</h3>
                  </EuiText>
                )}
                labelAppend={(
                  <EuiButton
                    fill
                    iconType="indexEdit"
                    isDisabled={Boolean(responseData)}
                    onClick={() => _updateForm({ ...utils.autofill(ResourceEnums.ResourceTypes.Entities.key, teamData, requestData.type) })}>
                    Fill with sample data
                  </EuiButton>
                )} />
            </>
          )}

          <EuiFormRow fullWidth>
            <>
              {/* individual */}
              {requestData.type === ResourceEnums.EntityTypes.Individual.key && [
                <EuiFormRow fullWidth key="name">
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow fullWidth label="First Name" isInvalid={submitAttempted && validationErrors.includes('individual.first_name')}>
                        <EuiFieldText fullWidth disabled={responseData} value={requestData.firstName} isInvalid={submitAttempted && validationErrors.includes('individual.first_name')} placeholder="Kevin" onChange={e => _updateForm({ firstName: e.target.value })} />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow fullWidth label="Last Name" isInvalid={submitAttempted && validationErrors.includes('individual.last_name')}>
                        <EuiFieldText fullWidth disabled={responseData} value={requestData.lastName} isInvalid={submitAttempted && validationErrors.includes('individual.last_name')} placeholder="Doyle" onChange={e => _updateForm({ lastName: e.target.value })} />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFormRow>,
                <EuiFormRow fullWidth key="email-phone">
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow fullWidth label="Email" isInvalid={submitAttempted && validationErrors.includes('individual.email')}>
                        <EuiFieldText fullWidth disabled={responseData} value={requestData.email} isInvalid={submitAttempted && validationErrors.includes('individual.email')} placeholder="kevin.doyle@gmail.com" onChange={e => _updateForm({ email: e.target.value })} />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow fullWidth label="Phone" isInvalid={submitAttempted && validationErrors.includes('individual.phone')}>
                        <EuiFieldText fullWidth disabled={responseData} value={requestData.phone} isInvalid={submitAttempted && validationErrors.includes('individual.phone')} placeholder="+16505555555" onChange={e => _updateForm({ phone: e.target.value })} />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFormRow>,
                <EuiFormRow fullWidth key="dob" label="Date of Birth" isInvalid={submitAttempted && validationErrors.includes('individual.dob')}>
                  <EuiFieldText fullWidth disabled={responseData} value={requestData.dob} isInvalid={submitAttempted && validationErrors.includes('individual.dob')} placeholder="1997-03-18" onChange={e => _updateForm({ dob: e.target.value })} />
                </EuiFormRow>,
              ]}
              {/* corporation */}
              {requestData.type && requestData.type !== ResourceEnums.EntityTypes.Individual.key && [
                <EuiFormRow fullWidth key="name-ein">
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow fullWidth label="Name" isInvalid={submitAttempted && validationErrors.includes('corporation.name')}>
                        <EuiFieldText fullWidth disabled={responseData} value={requestData.name} isInvalid={submitAttempted && validationErrors.includes('corporation.name')} placeholder="Alphabet Inc." onChange={e => _updateForm({ name: e.target.value })} />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow fullWidth label="EIN" isInvalid={submitAttempted && validationErrors.includes('corporation.ein')}>
                        <EuiFieldText fullWidth disabled={responseData} value={requestData.ein} isInvalid={submitAttempted && validationErrors.includes('corporation.ein')} placeholder="641234567" onChange={e => _updateForm({ ein: e.target.value })} />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFormRow>,
              ]}
            </>
          </EuiFormRow>

          {/* address */}
          {requestData.type && [
            <EuiFormRow fullWidth key="addressLine1-addressLine2">
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow fullWidth label="Address Line 1" isInvalid={submitAttempted && validationErrors.includes('address.line1')}>
                    <EuiFieldText fullWidth disabled={responseData} value={requestData.addressLine1} isInvalid={submitAttempted && validationErrors.includes('address.line1')} placeholder="1 Apple Park Way" onChange={e => _updateForm({ addressLine1: e.target.value })} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow fullWidth label="Address Line 2" isInvalid={submitAttempted && validationErrors.includes('address.line2')}>
                    <EuiFieldText fullWidth disabled={responseData} value={requestData.addressLine2} isInvalid={submitAttempted && validationErrors.includes('address.line2')} placeholder="Optional" onChange={e => _updateForm({ addressLine2: e.target.value })} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>,
            <EuiFormRow fullWidth key="addressCity-addressState-addressZip">
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow fullWidth label="City" isInvalid={submitAttempted && validationErrors.includes('address.city')}>
                    <EuiFieldText fullWidth disabled={responseData} value={requestData.addressCity} isInvalid={submitAttempted && validationErrors.includes('address.city')} placeholder="Cupertino" onChange={e => _updateForm({ addressCity: e.target.value })} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow fullWidth label="State" isInvalid={submitAttempted && validationErrors.includes('address.state')}>
                    <EuiSelect
                      fullWidth
                      hasNoInitialSelection
                      disabled={responseData}
                      options={utils.states}
                      value={requestData.addressState}
                      isInvalid={submitAttempted && validationErrors.includes('address.state')}
                      onChange={e => _updateForm({ addressState: e.target.value })} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow fullWidth label="Zip" isInvalid={submitAttempted && validationErrors.includes('address.zip')}>
                    <EuiFieldText fullWidth disabled={responseData} value={requestData.addressZip} isInvalid={submitAttempted && validationErrors.includes('address.zip')} placeholder="95014" maxLength={5} onChange={e => _updateForm({ addressZip: e.target.value })} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>,
          ]}
        </EuiForm>
      </EuiFlexItem>
      <EuiFlexItem>
        {/* submit */}
        <EuiButton fill fullWidth isDisabled={Boolean(isLoading || responseData)} isLoading={isLoading} onClick={_onSubmit}>Create Entity</EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default Entities;
