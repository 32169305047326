export function getParams() {
  return (new URL(window.location)).searchParams;
}

export function getToken() {
  return getParams().get('tok', null);
}

export function isProMode() {
  return getParams().get('pro', false) === 'true';
}

export function isPlaidFlowMode() {
  return getParams().get('flow', false) === 'true';
}
