import * as React from 'react';
import {
  EuiCollapsibleNavGroup,
  EuiListGroup,
  EuiListGroupItem,
  EuiBadge,
  EuiCollapsibleNav,
  EuiShowFor,
  EuiPageSideBar,
} from '@elastic/eui';

import * as ResourcesEnums from '../../../enums/Resources';
import * as utils from '../../../utils';


function Sidebar(props) {
  const { teamData, setCodeBlockModalState, isOpen, closeSidebar } = props;
  const extractableTeam = utils.helpers.extractFromTeamData(teamData);

  const collapsibleNavGroups = [
    <EuiCollapsibleNavGroup key="entities" isCollapsible title={`${ResourcesEnums.ResourceTypes.Entities.emoji} ${ResourcesEnums.ResourceTypes.Entities.presentable}`.toUpperCase()}>
      <EuiListGroup flush gutterSize="s" style={{ marginLeft: 10 }}>
        {teamData.entities.map((entity) => {
          const emoji = entity.type === ResourcesEnums.EntityTypes.Individual.key
            ? ResourcesEnums.EntityTypes.Individual.emoji
            : ResourcesEnums.EntityTypes.CCorporation.emoji;
          const name = extractableTeam.toPresentableEntity(entity);
          const onClick = () => {
            setCodeBlockModalState({
              open: true,
              title: 'Entity',
              subtitle: name,
              resourceType: ResourcesEnums.ResourceTypes.Entities.key,
              requestType: 'GET',
              resource: entity,
            });
          };
          const badges = extractableTeam.getBadgesForResource(entity, ResourcesEnums.ResourceTypes.Entities.key);
          return (
            <EuiListGroupItem
              wrapText color="subdued"
              key={entity.id}
              size="s"
              label={
                <>
                  <p>{emoji} {name}</p>
                  {badges.map(badge => (
                    <EuiBadge key={`${entity.id}-${badge.text}`} color={badge.color}>
                      <small><b>{badge.text}</b></small>
                    </EuiBadge>
                  ))}
                </>
              }
              onClick={onClick}
              extraAction={{
                color: 'primary',
                onClick: onClick,
                iconType: 'console',
                iconSize: 's',
                alwaysShow: false,
              }} />
          );
        })}
      </EuiListGroup>
    </EuiCollapsibleNavGroup>,
    <EuiCollapsibleNavGroup key="accounts" isCollapsible title={`${ResourcesEnums.ResourceTypes.Accounts.emoji} ${ResourcesEnums.ResourceTypes.Accounts.presentable}`.toUpperCase()}>
      <EuiListGroup flush gutterSize="s" style={{ marginLeft: 10 }}>
        {teamData.accounts.map((account) => {
          const name = extractableTeam.toPresentableAccount(account);
          const accountType = account.type;
          const isACH = accountType === ResourcesEnums.AccountTypes.ACH.key;
          const emoji = isACH ? ResourcesEnums.AccountTypes.ACH.emoji : ResourcesEnums.AccountTypes.Liability.emoji;
          const onClick = () => {
            setCodeBlockModalState({
              open: true,
              title: 'Account',
              subtitle: name,
              resourceType: ResourcesEnums.ResourceTypes.Accounts.key,
              requestType: 'GET',
              resource: account,
            });
          };
          const badges = extractableTeam.getBadgesForResource(account, ResourcesEnums.ResourceTypes.Accounts.key);
          return (
            <EuiListGroupItem
              wrapText
              color="subdued"
              key={account.id}
              size="s"
              label={
                <>
                  <p>{emoji} {name}</p>
                  {badges.map(badge => (
                    <EuiBadge key={`${account.id}-${badge.text}`} color={badge.color}>
                      <small><b>{badge.text}</b></small>
                    </EuiBadge>
                  ))}
                </>
              }
              onClick={onClick}
              extraAction={{
                color: 'primary',
                onClick: onClick,
                iconType: 'console',
                iconSize: 's',
                alwaysShow: false,
              }} />
          );
        })}
      </EuiListGroup>
    </EuiCollapsibleNavGroup>,
    <EuiCollapsibleNavGroup key="payments" isCollapsible title={`${ResourcesEnums.ResourceTypes.Payments.emoji} ${ResourcesEnums.ResourceTypes.Payments.presentable}`.toUpperCase()}>
      <EuiListGroup flush gutterSize="s" style={{ marginLeft: 10 }}>
        {teamData.payments.map((payment) => {
          const emoji = ResourcesEnums.ResourceTypes.Payments.emoji;
          const name = extractableTeam.toPresentablePayment(payment);
          const onClick = () => {
            setCodeBlockModalState({
              open: true,
              title: 'Payment',
              subtitle: name,
              resourceType: ResourcesEnums.ResourceTypes.Payments.key,
              requestType: 'GET',
              resource: payment,
            });
          };
          const badges = extractableTeam.getBadgesForResource(payment, ResourcesEnums.ResourceTypes.Payments.key);
          return (
            <EuiListGroupItem
              wrapText
              color="subdued"
              key={payment.id}
              size="s"
              label={
                <>
                  <p>{emoji} {name}</p>
                  {badges.map(badge => (
                    <EuiBadge key={`${payment.id}-${badge.text}`} color={badge.color}>
                      <small><b>{badge.text}</b></small>
                    </EuiBadge>
                  ))}
                </>
              }
              onClick={onClick}
              extraAction={{
                color: 'primary',
                onClick: onClick,
                iconType: 'console',
                iconSize: 's',
                alwaysShow: false,
              }} />
          );
        })}
      </EuiListGroup>
    </EuiCollapsibleNavGroup>,
  ];

  return [
    <EuiShowFor key="navForWide" sizes={['xl']}>
      <EuiPageSideBar sticky style={{ minWidth: 300, maxWidth: 300, height: 'calc(100vh - 48px)', marginTop: 48, paddingBottom: 200 }}>
        {collapsibleNavGroups}
      </EuiPageSideBar>
    </EuiShowFor>,
    <EuiShowFor key="navForMobile" sizes={['xs', 's', 'm', 'l']}>
      <EuiCollapsibleNav isOpen={isOpen} onClose={closeSidebar} style={{ minWidth: 300, maxWidth: 300, height: 'calc(100vh - 48px)', marginTop: 48, paddingBottom: 200, overflowY: 'auto' }}>
        {collapsibleNavGroups}
      </EuiCollapsibleNav>
    </EuiShowFor>,
  ];
}
export default Sidebar;
